import React from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import Tabs from 'ck_commun/src/app/modUtils/components/componentsLibrary/tabs/Tabs';
import { Input } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';

export default function TransactionsPrepaymentInSale({ paymentList }) {
    const { TabPane } = Tabs;
    const { t } = useTranslation();

    const tabsToRender = paymentList?.map((payment, index) => {
        const dataPayment = [
            {
                label: 'table.tabColAmount',
                value: amountFormatWithCurrencySymbol(payment?.amount),
            }, // string amount
            {
                label: 'table.tabColDate',
                value: dateFormat(payment?.date),
            }, // date
            {
                label: 'table.tabColEquipment',
                value: payment?.equipment,
            }, // int
            {
                label: 'table.tabColId',
                value: payment?.id,
            }, // int
            {
                label: 'selection_count',
                value: payment?.selection_count,
            }, // int
            {
                label: 'selection_id',
                value: payment?.selection_id,
            }, // int
            {
                label: 'support_prepayment',
                value: payment?.support_prepayment,
            }, // int
            {
                label: 'transaction_local_id',
                value: payment?.transaction_local_id,
            }, // int
        ];

        return (
            <TabPane
                label={'transactions Support Prepayment'} // TODO TRAD A FAIRE
                tabKey={`transactions-support-prepayment-tab-${index}`}
                key={`transactions-support-prepayment-tab-${index}`}
            >
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 px-9'>
                    {dataPayment.map((item, index) => (
                        <div key={index} className='col-span-1'>
                            <span>{t(item.label)}</span>
                            <Input
                                className='w-full'
                                size={30}
                                disabled
                                value={item.value}
                            />
                        </div>
                    ))}
                </div>
            </TabPane>
        );
    });

    return tabsToRender?.length > 0 ? (
        <Tabs classNameTabContent='bg-secondary-color-50 mt-4'>
            {tabsToRender}
        </Tabs>
    ) : null;
}
