import { useTranslation } from 'react-i18next';
import { amountFormatWithCurrencySymbol } from '../../../modUtils/functions/values/numberFormat';
import { dateFormat } from '../../../modUtils/functions/values/dateFormat';
import { ePromotionTypes } from '../../assets/js/ennum';
import { useSelector } from 'react-redux';
import { eEquipmentType } from '../../../../commun_global/enums';

const LastStepperScreen = ({ values }) => {
    const { t } = useTranslation();
    const { sites } = useSelector((state) => state.manager);
    const { equipmentTypeGroup } = useSelector((state) => state.equipment);

    return (
        <div className='LastStepperScreen text-center flex-display justify-content-between flex-column align-items-center'>
            <div className='flex-display flex-column align-items-center text-xl leading-9'>
                {t('createPromotionModal.transSuccessCreatePromotionModal')}
                <span className='font-extrabold'>“{values.promotionName}“</span>
            </div>
            <p className='text-lg mt-14'>
                {t('createPromotionModal.transTextShowcard')}
            </p>
            <div className='border border-solid border-[#4D4D4D42] rounded w-full flex flex-col sm:flex-row p-2'>
                <div
                    className={`border-r-0 sm:border-r border-b sm:border-b-0 pb-2 sm:pb-0 border-l-0 border-t-0 border-solid border-[#4D4D4D42] px-3.5 w-full ${values.typePromo ? 'sm:w-1/3' : 'sm:w-1/2'}`}
                >
                    <p className='text-[15px] font-extrabold leading-[20px]'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.showCard.transTitleDesc',
                        )}
                    </p>
                    <p className='mt-2 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.showCard.transPromotionTitle',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {values.promotionName}
                    </p>
                    {values.description && (
                        <>
                            <p className='mt-2 text-left mb-0 text-sm'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transDescription',
                                )}
                            </p>
                            <p className='text-left mb-0 text-sm font-semibold line-clamp-4'>
                                {values.description}
                            </p>
                        </>
                    )}
                    <p className='mt-2 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.showCard.transTypePromotion',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {t(ePromotionTypes[values.typePromo])}
                    </p>
                    {values.typePromo ? (
                        <>
                            <p className='mt-2 text-left mb-0 text-sm'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transOfferValidity',
                                )}
                            </p>
                            <p className='text-left mb-0 text-sm font-semibold'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transFrom',
                                )}{' '}
                                {dateFormat(
                                    values.startDateOfferObtainPromo,
                                    'DD/MM/YYYY',
                                )}{' '}
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.transTo',
                                )}{' '}
                                {dateFormat(
                                    values.endDateOfferObtainPromo,
                                    'DD/MM/YYYY',
                                )}
                            </p>
                        </>
                    ) : null}
                </div>
                {values.typePromo ? (
                    <div className='border-r-0 sm:border-r border-b sm:border-b-0 pb-2 sm:pb-0 border-l-0 border-t-0 border-solid border-[#4D4D4D42] px-3.5 w-full sm:w-1/3'>
                        <p className='text-[15px] font-extrabold leading-[20px]'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transPromotionPrintingCondition',
                            )}
                        </p>
                        {values.maximumPrintingObtainPromo && (
                            <>
                                <p className='mt-2 text-left mb-0 text-sm'>
                                    {t(
                                        'createPromotionModal.stepperCreatePromotion.showCard.transNumberOfOfferDelivered',
                                    )}
                                </p>
                                <p className='text-left mb-0 text-sm font-semibold'>
                                    {values.maximumPrintingObtainPromo}
                                </p>
                            </>
                        )}
                        {values.sitesObtainPromo && (
                            <>
                                <p className='mt-2 text-left mb-0 text-sm'>
                                    {t(
                                        'createPromotionModal.stepperCreatePromotion.showCard.transListOfSites',
                                    )}
                                </p>
                                <p className='text-left mb-0 text-sm font-semibold'>
                                    {values.sitesObtainPromo
                                        .map((siteId) => {
                                            const site = sites.find(
                                                (site) => site.id === siteId,
                                            );
                                            return site ? site.name : null;
                                        })
                                        .filter((name) => name !== null)
                                        .join(', ')}
                                </p>
                            </>
                        )}
                        {values.equipementsTypeObtainPromo && (
                            <>
                                <p className='mt-2 text-left mb-0 text-sm'>
                                    {t(
                                        'createPromotionModal.stepperCreatePromotion.showCard.transListOfEquipment',
                                    )}
                                </p>
                                <p className='text-left mb-0 text-sm font-semibold'>
                                    {values.equipementsTypeObtainPromo
                                        .map((equipmentId) => {
                                            const equipment =
                                                equipmentTypeGroup.find(
                                                    (equipment) =>
                                                        equipment.type ===
                                                        equipmentId,
                                                );
                                            return equipment
                                                ? t(
                                                      eEquipmentType[
                                                          equipment.type
                                                      ],
                                                  )
                                                : null;
                                        })
                                        .filter((name) => name !== null)
                                        .join(', ')}
                                </p>
                            </>
                        )}
                        <p className='mt-2 text-left mb-0 text-sm'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.showCard.transUseHours',
                            )}
                        </p>
                        <p className='text-left mb-0 text-sm font-semibold'>
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transFrom',
                            )}{' '}
                            {dateFormat(
                                values.startDateOfferObtainPromo,
                                'DD/MM/YYYY',
                            )}{' '}
                            {t(
                                'createPromotionModal.stepperCreatePromotion.transTo',
                            )}{' '}
                            {dateFormat(
                                values.endDateOfferObtainPromo,
                                'DD/MM/YYYY',
                            )}
                        </p>
                    </div>
                ) : null}
                <div className=' px-3.5 w-full sm:w-1/3'>
                    <p className='text-[15px] font-extrabold leading-[20px]'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transConditionUsePromotion',
                        )}
                    </p>
                    <p className='mt-2 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transDiscount',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {values.amountUsePromo
                            ? `${values.amountUsePromo} ${values.discountAmount ? '%' : localStorage.getItem('currencySymbol')}`
                            : t(
                                  'createPromotionModal.stepperCreatePromotion.showCard.transNotAssigned',
                              )}
                    </p>
                    <p className='mt-2 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transUniqueUseCode',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {values.maximumPrintingUsePromo === '1'
                            ? `1 ${t('createPromotionModal.stepperCreatePromotion.transTimes')}`
                            : values.maximumPrintingUsePromo === 'illimited'
                              ? t(
                                    'createPromotionModal.stepperCreatePromotion.transUnlimited',
                                )
                              : `${values.maximumPrintingUsePromo} ${t('createPromotionModal.stepperCreatePromotion.transTimes')}`}
                    </p>

                    {values.sitesUsePromo && (
                        <>
                            <p className='mt-2 text-left mb-0 text-sm'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.showCard.transListOfSites',
                                )}
                            </p>
                            <p className='text-left mb-0 text-sm font-semibold'>
                                {values.sitesUsePromo
                                    .map((siteId) => {
                                        const site = sites.find(
                                            (site) => site.id === siteId,
                                        );
                                        return site ? site.name : null;
                                    })
                                    .filter((name) => name !== null)
                                    .join(', ')}
                            </p>
                        </>
                    )}
                    {values.equipementsTypeUsePromo && (
                        <>
                            <p className='mt-2 text-left mb-0 text-sm'>
                                {t(
                                    'createPromotionModal.stepperCreatePromotion.showCard.transListOfEquipment',
                                )}
                            </p>
                            <p className='text-left mb-0 text-sm font-semibold'>
                                {values.equipementsTypeUsePromo
                                    .map((equipmentId) => {
                                        const equipment =
                                            equipmentTypeGroup.find(
                                                (equipment) =>
                                                    equipment.type ===
                                                    equipmentId,
                                            );
                                        return equipment
                                            ? t(eEquipmentType[equipment.type])
                                            : null;
                                    })
                                    .filter((name) => name !== null)
                                    .join(', ')}
                            </p>
                        </>
                    )}
                    <p className='mt-2 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transDailyValidity',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold lowercase'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transStarting',
                        )}{' '}
                        {values.startDayValidityUsePromo || '0'}{' '}
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transUpTo',
                        )}{' '}
                        {values.endDayValidityUsePromo || '0'}
                    </p>
                    <p className='mt-2 text-left mb-0 text-sm'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transBuyBetween',
                        )}
                    </p>
                    <p className='text-left mb-0 text-sm font-semibold'>
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transMinOf',
                        )}{' '}
                        {amountFormatWithCurrencySymbol(
                            values.minUsePromo ? values.minUsePromo : 0,
                        )}{' '}
                        {t(
                            'createPromotionModal.stepperCreatePromotion.transMaxOf',
                        )}{' '}
                        {amountFormatWithCurrencySymbol(
                            values.maxUsePromo ? values.maxUsePromo : 0,
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LastStepperScreen;
