import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet, httpPut } from '../../modApi/apis/httpConfig';

export const update_items_image = createAsyncThunk(
    'mod_sale_management/update_items_image',
    async (values, { rejectWithValue }) => {
        const formData = new FormData();
        formData.append('image', values.image);
        try {
            const response = await httpPut(
                `mod_sale_management/groups/${values.groupId}/items/${values.itemId}/image/`,
                formData,
            );
            console.log(response.data);
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

export const get_sale_vat_paginated = createAsyncThunk(
    'mod_sale_management/get_sale_vat_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_sale_management/groups/${values.groupId}/vat`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const saleManagementSlice = createSlice({
    name: 'saleManagement',
    initialState: {
        vatRates: [],
        items: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            /* update_items_image */
            .addCase(update_items_image.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload;
            })

            .addCase(update_items_image.pending, (state, action) => {
                state.items = [];
            })

            .addCase(update_items_image.rejected, (state, action) => {
                state.loading = false;
            })

            /* get_sale_vat_paginated */
            .addCase(get_sale_vat_paginated.fulfilled, (state, action) => {
                state.loading = false;
                state.vatRates = action.payload.results;
            })

            .addCase(get_sale_vat_paginated.pending, (state, action) => {
                state.loading = [];
            })

            .addCase(get_sale_vat_paginated.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const {} = saleManagementSlice.actions;

export default saleManagementSlice.reducer;
