import React, { useState } from 'react';
import { message } from 'antd';
import { FaEdit } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from 'ck_commun/src/app/modUtils/components/AlertMessage';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';
import ConfirmationModal from 'ck_commun/src/app/modUtils/components/confirmationModal/ConfirmationModal';
import { update_transaction_detail_by_group } from 'ck_commun/src/app/modWalletLocal/slices/walletLocalSlice';
import { get_transactions_by_support_by_group_paginated } from 'ck_commun/src/app/modWalletLocal/slices/walletLocalSlice';
import CkTablePaginatedScroll from 'ck_commun/src/app/modUtils/components/componentsLibrary/table/CkTablePaginatedScroll';

export default function SupportTransactionsList({ supportId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId } = useSelector((state) => state.manager);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [transactionToEdit, setTransactionToEdit] = useState();
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const getSupportTransactions = async (limit, offset) => {
        const response = await dispatch(
            get_transactions_by_support_by_group_paginated({
                groupId,
                supportId,
                ordering: '-date',
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    const updateTransactionIsSuspectedFraud = async () => {
        setConfirmLoading(true);
        try {
            await dispatch(
                update_transaction_detail_by_group({
                    groupId,
                    supportId,
                    transactionId: transactionToEdit?.id,
                    isSuspectedFraud: !transactionToEdit.is_suspected_fraud,
                }),
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('supportUnlinkFromCustomer.error')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        } finally {
            setConfirmLoading(false);
            setConfirmModalVisible(false);
            setTransactionToEdit();
        }
    };

    const handleEditTransaction = (transaction) => {
        setTransactionToEdit(transaction);
        setConfirmModalVisible(true);
    };

    const handleConfirmCancel = () => {
        setConfirmModalVisible(false);
        setTransactionToEdit();
    };

    const columns = [
        {
            title: t('table.tabColLastVisit'),
            dataIndex: 'date_probable',
            key: 'date_probable',
            className: 'w-[10%] text-[11px]',
            headerClassName: 'text-[11px]',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColEquipment'),
            dataIndex: 'equipment_name',
            key: 'equipment_name',
            className: 'text-left w-[20%] text-[11px]',
            headerClassName: 'text-[11px]',
        },
        {
            title: t('table.tabColAmount'),
            dataIndex: 'amount',
            key: 'amount',
            className: 'text-right font-bold w-[10%] text-[11px]',
            headerClassName: 'text-[11px]',
            render: (text) => (
                <span className='truncate'>
                    {amountFormatWithCurrencySymbol(text)}
                </span>
            ),
        },
        {
            title: t('table.tabColBonus'),
            dataIndex: 'prime',
            key: 'prime',
            className: 'text-right font-bold w-[10%] text-[11px]',
            headerClassName: 'text-[11px]',
            render: (text) => (
                <span className='truncate'>
                    {amountFormatWithCurrencySymbol(text)}
                </span>
            ),
        },
        {
            title: t('table.tabColAccountBalanceBefore'),
            dataIndex: 'account_balance_before',
            key: 'account_balance_before',
            className: 'text-right font-bold w-[10%] text-[11px]',
            headerClassName: 'text-[11px]',
            render: (text) => (
                <span className='truncate'>
                    {amountFormatWithCurrencySymbol(text)}
                </span>
            ),
        },
        {
            title: t('table.tabColAccountBalance'),
            dataIndex: 'account_balance',
            key: 'account_balance',
            className: 'table-column-accent w-[10%] text-[11px]',
            headerClassName: 'table-column-accent text-[11px]',
            headerClassName: 'text-[11px]',
            render: (text) => (
                <span className='truncate'>
                    {amountFormatWithCurrencySymbol(text)}
                </span>
            ),
        },
        {
            title: 'FRAUDES',
            dataIndex: 'is_suspected_fraud',
            key: 'is_suspected_fraud',
            className: 'w-[5%]',
            headerClassName: 'text-[11px]',
            render: (text, record) => (
                <div className='flex items-center justify-between'>
                    {text == true && (
                        <img
                            className='mr-2'
                            width={20}
                            src={
                                process.env.PUBLIC_URL +
                                '/img/badgesIcons/fraudeIcone.svg'
                            }
                        />
                    )}
                    <span
                        className='ml-auto'
                        onClick={() => handleEditTransaction(record)}
                    >
                        <FaEdit color='#338EF5' size={20} />
                    </span>
                </div>
            ),
        },
    ];

    return (
        <>
            <CkTablePaginatedScroll
                columns={columns}
                fetch={getSupportTransactions}
                maxHeight='max-h-[39vh]'
                effect={supportId}
            />

            {/* Modal de confirmation pour modifier état suspect_fraud d'une transaction*/}
            <ConfirmationModal
                onOk={updateTransactionIsSuspectedFraud}
                onCancel={handleConfirmCancel}
                visible={confirmModalVisible}
                confirmLoading={confirmLoading}
                content={
                    <span>
                        {transactionToEdit?.is_suspected_fraud === false
                            ? t('supportTransactionsList.reportMessage')
                            : t('supportTransactionsList.removeMessage')}
                    </span>
                }
            />
        </>
    );
}
