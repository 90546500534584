import bankingSlice from 'ck_commun/src/app/modBanking/slices/bankingSlice';
import cumulativeSlice from 'ck_commun/src/app/modCumulative/slices/cumulativeSlice';
import entitySlice from 'ck_commun/src/app/modEntity/slices/entitySlice';
import fixingSlice from 'ck_commun/src/app/modFixing/slices/fixingSlice';
import machineSlice from 'ck_commun/src/app/modImachine/slices/machineSlice';
import weatherSlice from 'ck_commun/src/app/modWeather/slices/weatherSlice';
import sharedEquipmentSlice from 'ck_commun/src/app/modSharedEquipment/slices/sharedEquipmentSlice';
import walletLocalSlice from 'ck_commun/src/app/modWalletLocal/slices/walletLocalSlice';
import statementSlice from 'ck_commun/src/app/modStatement/slices/statementSlice';
import subscriptionSlices from 'ck_commun/src/app/modSubscription/slices/subscriptionSlices';
import supportSlices from 'ck_commun/src/app/modSupport/slices/supportSlices';
import supportCentralizedSlices from 'ck_commun/src/app/modSupportCentralized/slices/supportCentralizedSlices';
import supportPrepaymentSlices from 'ck_commun/src/app/modSupportPrepayment/slices/supportPrepaymentSlices';
import walletCentralizedSlices from 'ck_commun/src/app/modWalletCentralized/slices/walletCentralizedSlices';
import apiSlice from 'ck_commun/src/app/modApi/slices/apiSlice';
import profilReducer from 'ck_commun/src/app/modProfil/slices/indexSlice';
import promotionSlice from 'ck_commun/src/app/modPromotion/slices/promotionSlice';
import { IS_PROD } from 'ck_commun/src/app/modUtils/utils/constants';

const rootReducer = {
    banking: bankingSlice,
    cumulative: cumulativeSlice,
    entity: entitySlice,
    fixing: fixingSlice,
    machine: machineSlice,
    weather: weatherSlice,
    sharedEquipment: sharedEquipmentSlice,
    walletLocal: walletLocalSlice,
    statement: statementSlice,
    support: supportSlices,
    promotion: promotionSlice,
    api: apiSlice,
};

// module temporaiement installé en development uniquement
if (!IS_PROD) {
    rootReducer.profil = profilReducer;
    rootReducer.supportCentralized = supportCentralizedSlices;
    rootReducer.supportPrepayment = supportPrepaymentSlices;
    rootReducer.walletCentralized = walletCentralizedSlices;
    rootReducer.subscription = subscriptionSlices;
}

export default rootReducer;
