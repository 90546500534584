import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tabs from 'ck_commun/src/app/modUtils/components/componentsLibrary/tabs/Tabs';
import { Input } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/permissions/handlePermissions';
import PermissionDeniedPage from 'ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage';
import PermissionFadeContainer from 'ck_commun/src/app/modUtils/components/permissionFadeContainer/PermissionFadeContainer';

export default function TransactionsLocalInSale({ paymentList }) {
    const { TabPane } = Tabs;
    const { t } = useTranslation();
    const { saleDetails } = useSelector((state) => state.sale);
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_wallet_local:manage_support',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_wallet_local:manage_support',
        groupPermissions,
    );

    const tabsToRender = paymentList?.map((payment, index) => {
        const dataPayment = [
            {
                label: 'transactionsLocalInSale.badgeName',
                value: saleDetails?.identification?.label,
            },
            {
                label: 'transactionsLocalInSale.creditStart',
                value: payment?.account_balance_before,
            },
            {
                label: 'transactionsLocalInSale.transactionAmount',
                value: payment?.amount,
            },
            { isDivider: true },
            {
                label: 'transactionsLocalInSale.endTransaction',
                value: payment?.account_balance,
            },
        ];

        return (
            <TabPane
                label={t('saleDetailsModal.badgePaymentInformation')}
                tabKey={`transactions-support-prepayment-tab-${index}`}
                key={`transactions-support-prepayment-tab-${index}`}
            >
                <div className='p-5 w-1/2'>
                    {!verifyPermission ? (
                        <PermissionDeniedPage
                            direction='row'
                            permission={verifyGroupPermission}
                        />
                    ) : (
                        <PermissionFadeContainer
                            permission={verifyGroupPermission}
                        >
                            {dataPayment.map((item, index) =>
                                item.isDivider ? (
                                    <hr />
                                ) : (
                                    <div
                                        key={index}
                                        className='flex flex-row justify-between'
                                    >
                                        <span>{t(item.label)}</span>
                                        <Input
                                            size={30}
                                            width='140px'
                                            disabled
                                            value={item.value}
                                        />
                                    </div>
                                ),
                            )}
                        </PermissionFadeContainer>
                    )}
                </div>
            </TabPane>
        );
    });

    return tabsToRender?.length > 0 ? (
        <Tabs classNameTabContent='bg-secondary-color-50 mt-4'>
            {tabsToRender}
        </Tabs>
    ) : null;
}
