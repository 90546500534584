import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import Tabs from 'ck_commun/src/app/modUtils/components/componentsLibrary/tabs/Tabs';
import { Input } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/permissions/handlePermissions';
import PermissionDeniedPage from 'ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';
import PermissionFadeContainer from 'ck_commun/src/app/modUtils/components/permissionFadeContainer/PermissionFadeContainer';

export default function TransactionsBankingInSale({ paymentList }) {
    const { TabPane } = Tabs;
    const { t } = useTranslation();
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission('mod_sale:show_sale', permissions);
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_sale:show_sale',
        groupPermissions,
    );

    const tabsToRender = paymentList?.map((payment, index) => {
        const dataPayment = [
            {
                label: 'transactionsBankingInSale.date',
                value: dateFormat(payment?.date_creation),
            },
            {
                label: 'transactionsBankingInSale.amount',
                value: amountFormatWithCurrencySymbol(payment?.amount),
            },
            {
                label: 'transactionsBankingInSale.authorisation',
                value: payment?.autorisation,
            },
            {
                label: 'transactionsBankingInSale.discountNumber',
                value: payment?.remise_number,
            },
            {
                label: 'transactionsBankingInSale.transactionNumber',
                value: payment?.transaction_number,
            },
            { label: 'transactionsBankingInSale.siret', value: payment?.siret },
            {
                label: 'transactionsBankingInSale.creditCardNumber',
                value: payment?.credit_card_number,
            },
            {
                label: 'transactionsBankingInSale.taught',
                value: payment?.enseigne,
            },
            {
                label: 'transactionsBankingInSale.logicalNumber',
                value: payment?.logical_number,
            },
            {
                label: 'transactionsBankingInSale.overrideCode',
                value: payment?.force_code,
            },
            {
                label: 'transactionsBankingInSale.readingMode',
                value: payment?.reading_mod,
            },
            {
                label: 'transactionsBankingInSale.applicationId',
                value: payment?.application_id,
            },
            {
                label: 'transactionsBankingInSale.applicationLabel',
                value: payment?.application_label,
            },
        ];

        return (
            <TabPane
                label={t('saleDetailsModal.creditCardPaymentInformation')}
                tabKey={`transactions-support-prepayment-tab-${index}`}
                key={`transactions-support-prepayment-tab-${index}`}
            >
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 px-9'>
                    {!verifyPermission ? (
                        <PermissionDeniedPage
                            direction='row'
                            permission={verifyGroupPermission}
                        />
                    ) : (
                        <PermissionFadeContainer
                            permission={verifyGroupPermission}
                        >
                            {dataPayment.map((item, index) => (
                                <div key={index} className='col-span-1'>
                                    <span>{t(item.label)}</span>
                                    <Input
                                        className='w-full'
                                        size={30}
                                        disabled
                                        value={item.value}
                                    />
                                </div>
                            ))}
                        </PermissionFadeContainer>
                    )}
                </div>
            </TabPane>
        );
    });

    return tabsToRender?.length > 0 ? (
        <Tabs classNameTabContent='bg-secondary-color-50 mt-4'>
            {tabsToRender}
        </Tabs>
    ) : null;
}
