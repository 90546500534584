import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/permissions/handlePermissions';
import PermissionDeniedPage from 'ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage';
import { get_events_by_equipment_by_group_paginated } from 'ck_commun/src/app/modEquipment/slices/equipmentSlice';
import CkTablePaginatedScroll from 'ck_commun/src/app/modUtils/components/componentsLibrary/table/CkTablePaginatedScroll';
import PermissionFadeContainer from 'ck_commun/src/app/modUtils/components/permissionFadeContainer/PermissionFadeContainer';

export default function EquipmentEventsHistory({ equipment, isModalVisible }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId, selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_equipment:show_event',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_equipment:show_event',
        groupPermissions,
    );

    const getEventsByEquipmentPaginatedByGroup = async (limit, offset) => {
        const response = await dispatch(
            get_events_by_equipment_by_group_paginated({
                groupId: groupId,
                equipmentId: equipment.id,
                limit: limit,
                offset: offset,
                ordering: '-date',
            }),
        ).unwrap();
        return response;
    };

    const columns = [
        {
            title: t('table.tabColDate'),
            dataIndex: 'date',
            key: 'date',
            className: 'w-[20%]',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColEvents'),
            dataIndex: 'description',
            key: 'description',
            className: 'w-[80%] font-bold text-left truncate max-w-[600px]',
            align: 'left',
            bold: true,
            width: '80%',
        },
    ];

    return (
        <>
            {!verifyPermission ? (
                <PermissionDeniedPage permission={verifyGroupPermission} />
            ) : (
                <PermissionFadeContainer
                    permission={verifyGroupPermission}
                    opacity={50}
                >
                    {isModalVisible && (
                        <CkTablePaginatedScroll
                            columns={columns}
                            fetch={getEventsByEquipmentPaginatedByGroup}
                            maxHeight='max-h-[690px]'
                        />
                    )}
                </PermissionFadeContainer>
            )}
        </>
    );
}
