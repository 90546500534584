import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import SupportActionMenuButton from 'ck_commun/src/app/modWalletLocal/components/SupportActionMenuButton';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';
import { get_actions_by_support_by_group_paginated } from 'ck_commun/src/app/modWalletLocal/slices/walletLocalSlice';
import CkTablePaginatedScroll from 'ck_commun/src/app/modUtils/components/componentsLibrary/table/CkTablePaginatedScroll';

export default function SupportActionsList({ supportId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { groupId } = useSelector((state) => state.manager);
    const { supportActionsCount } = useSelector((state) => state.walletLocal);

    const getSupportActions = async (limit, offset) => {
        const response = await dispatch(
            get_actions_by_support_by_group_paginated({
                groupId,
                supportId,
                ordering: '-date',
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    function getIconStatus(status) {
        let src;

        if (status === 0) {
            src = '/img/badgesIcons/actionEnAttente.png';
        } else if (status === 1) {
            src = '/img/badgesIcons/x.svg';
        } else if (status === 2) {
            src = '/img/badgesIcons/actionExecuted.png';
        }

        return <img src={process.env.PUBLIC_URL + src} />;
    }

    const columns = [
        {
            title: t('table.tabColDate'),
            dataIndex: 'request_date',
            key: 'request_date',
            className: 'w-[9%] text-[11px]',
            headerClassName: 'text-[11px]',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColOrigin'),
            dataIndex: 'origin_label',
            key: 'origin_label',
            className: 'w-[8%] text-[11px]',
            headerClassName: 'text-[11px]',
        },
        {
            title: t('table.tabColAction'),
            dataIndex: 'type_label',
            key: 'type_label',
            className: 'w-[10%] font-bold text-[11px] text-left',
            headerClassName: 'text-[11px]',
        },
        {
            title: t('table.tabColAmount'),
            dataIndex: 'amount',
            key: 'amount',
            className: 'w-[8%] font-bold text-[11px] text-right',
            headerClassName: 'text-[11px]',
            render: (text) => (
                <span>{amountFormatWithCurrencySymbol(text)}</span>
            ),
        },
        {
            title: t('table.tabColBonus'),
            dataIndex: 'prime',
            key: 'prime',
            className: 'w-[8%] font-bold text-[11px] text-right',
            headerClassName: 'text-[11px]',
            render: (text) => (
                <span>{amountFormatWithCurrencySymbol(text)}</span>
            ),
        },
        {
            title: t('table.tabColState'),
            dataIndex: 'status',
            key: 'status',
            className: 'w-[5%] text-center',
            headerClassName: 'text-[11px]',
            render: (text, record) => getIconStatus(text),
        },
        {
            title: t('table.tabColExecuted'),
            dataIndex: 'executed_date',
            key: 'executed_date',
            className: 'w-[13%] font-bold text-[11px] text-left',
            headerClassName: 'text-[11px]',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColEquipment'),
            dataIndex: 'executed_equipment',
            key: 'executed_equipment',
            className: 'w-[15%] font-bold text-[11px] text-left text-ellipsis',
            headerClassName: 'text-[11px]',
        },
        {
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            className: 'w-[5%]',
            render: (text, record) => (
                <SupportActionMenuButton
                    actionId={text}
                    actionStatus={record.status}
                    supportId={supportId}
                />
            ),
        },
    ];

    return (
        <>
            <div className='text-left font-semibold uppercase text-[#354052] text-[14px] leading-[18px]'>
                {/* {actionAwaitStatus} action(s) en attente */}
                {supportActionsCount} action(s)
            </div>

            <CkTablePaginatedScroll
                columns={columns}
                fetch={getSupportActions}
                maxHeight='max-h-[39vh]'
                effect={supportId}
            />
        </>
    );
}
