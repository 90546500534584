import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../components/componentsLibrary/componentsLibrary';
import MobilePage from './MobilePage';
import Cookies from 'js-cookie';

import { CURRENT_DOMAIN } from '../../modApi/apis/constants';

export default function ModalMobilePage() {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(true);
    const [isMediumScreen, setIsMediumScreen] = useState(false);
    const [showMobilPage, setShowMobilPage] = useState(false);

    useEffect(() => {
        // Crée un MediaQueryList pour le point de rupture 'md' de Tailwind (min-width: 768px)
        const mediaQuery = window.matchMedia('(min-width: 768px)');

        // Définie l'état en fonction du media query initial
        setIsMediumScreen(mediaQuery.matches);

        // Écoute les changements de media query
        const handleChange = () => {
            setIsMediumScreen(mediaQuery.matches);
        };

        mediaQuery.addEventListener('change', handleChange);

        // Nettoyage de l'écouteur lors du démontage du composant
        return () => {
            mediaQuery.removeEventListener('change', handleChange);
        };
    }, []);

    const handleClick = () => {
        setModalVisible(false);
        const expirationDays = 7; // Durée de vie de 15 jours
        Cookies.set('preferenceWebPages', 'true', {
            expires: expirationDays,
            domain: CURRENT_DOMAIN,
        });
    };

    const checkCookieExists = (cookieName) => {
        return Cookies.get(cookieName) !== undefined; // Vérifie si le cookie existe
    };

    useEffect(() => {
        const exists = checkCookieExists('preferenceWebPages');
        setShowMobilPage(!exists);
    }, []);

    return (
        <>
            {showMobilPage && !isMediumScreen && (
                <Modal
                    isOpen={modalVisible}
                    onClose={() => setModalVisible(false)}
                >
                    <MobilePage />
                    <div className='flex justify-center'>
                        <p
                            className='mt-8 text-primary-color cursor-pointer w-max hover:underline'
                            onClick={() => handleClick()}
                        >
                            {t('login.continueToWebsite')}
                        </p>
                    </div>
                </Modal>
            )}
        </>
    );
}
