import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { eCdbsPaymentType } from 'ck_commun/src/commun_global/enums';
import { Table } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';

export default function EquipmentSaleDetails({ sale }) {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const columns = [
        {
            title: t('equipmentSaleDetails.tableTypePayment'),
            dataIndex: 'type_payment',
            key: 'type_payment',
            width: '35%',
            render: (text) => t(eCdbsPaymentType[text]),
        },
        {
            title: t('equipmentSaleDetails.tableCount'),
            dataIndex: 'count',
            key: 'count',
            width: '35%',
            render: (text, record) => (
                <span>
                    {record?.count > 1 && record?.count + ' * '}
                    {amountFormatWithCurrencySymbol(record?.unit)}
                </span>
            ),
        },
        {
            title: t('equipmentSaleDetails.tableAmount'),
            dataIndex: 'amount',
            key: 'amount',
            width: '30%',
            render: (text, record) => (
                <span>{amountFormatWithCurrencySymbol(text)}</span>
            ),
        },
    ];

    const style = {
        th: {
            backgroundColor: '#F1F3F9',
            color: '#8898AA',
            fontSize: '11px',
            fontWeight: '500',
            height: '28px',
            padding: 0,
        },
        td: {
            height: '25px',
            padding: 0,
            border: '1px solid #F7FAFC',
        },
    };

    return (
        <div className='relative'>
            <div className='flex justify-center cursor-pointer'>
                <img
                    src={process.env.PUBLIC_URL + '/img/chevron-right.svg'}
                    alt='Chevron'
                    className={`transition-transform duration-300 ease-in-out relative transform
                            ${visible ? 'rotate-[270deg]' : 'rotate-[90deg]'}
                        `}
                    onClick={() => setVisible(!visible)}
                />
            </div>

            {visible && (
                <div
                    className='min-w-[750px] absolute right-[-355px] transform -translate-x-1/2 top-[20px] max-w-[800px] w-full bg-white shadow-lg border border-gray-300 p-4 rounded-lg'
                    style={{ maxHeight: '300px', overflowY: 'auto' }}
                >
                    <div className='tooltip-content flex flex-row justify-between bg-white shadow-md min-h-[150px] max-h-[263px] overflow-auto'>
                        <div className='w-[30%] flex flex-col justify-between items-start'>
                            <ul className='text-black text-xs font-semibold uppercase pl-4 max-h-[250px] overflow-auto'>
                                {t('equipmentSaleDetails.productsPurchased')}
                                {sale?.retails?.map((product) => (
                                    <li
                                        key={sale.id + product.label_local}
                                        className='font-medium list-none'
                                    >
                                        - {product?.label_local} (
                                        {product?.local_id}) X{' '}
                                        {product.quantity}
                                    </li>
                                ))}
                            </ul>

                            <button className='block mt-2'>
                                <div>
                                    {t('equipmentSaleDetails.priceTotal')}
                                </div>
                                <div>{sale?.price_total_selected} €</div>
                            </button>
                        </div>

                        <div className='w-[70%] overflow-x-auto'>
                            <Table
                                columns={columns}
                                dataSource={sale.payments}
                                style={style}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
