import React, { useState } from 'react';
import DropdownActionsMenu from '../../../modUtils/components/dropdownActionsMenu/DropdownActionsMenu';
import { useTranslation } from 'react-i18next';
import ModifyProductModal from './ModifyProductModal';

export default function ActionMenuButton({ productId }) {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [isModifyProductModalVisible, setIsModifyProductModalVisible] =
        useState(false);

    const handleVisibleChange = (newVisible) => {
        setVisible(newVisible);
    };

    const hide = () => {
        setVisible(false);
    };

    const handleOpenModifyProduct = () => {
        hide();
        setIsModifyProductModalVisible(true);
    };

    const menuItems = [
        <p onClick={handleOpenModifyProduct}>{t('menuButton.modifyButton')}</p>,
    ];

    return (
        <>
            <DropdownActionsMenu
                menuItems={menuItems}
                handleVisibleChange={handleVisibleChange}
                visible={visible}
            />

            {isModifyProductModalVisible && (
                <ModifyProductModal
                    modalTitle={t('viewModal.modalTitle')}
                    mode='create'
                    productId={productId}
                    modalVisible={isModifyProductModalVisible}
                    setModalVisible={setIsModifyProductModalVisible}
                />
            )}
        </>
    );
}
