import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { eEquipmentType } from 'ck_commun/src/commun_global/enums.js';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import Tabs from 'ck_commun/src/app/modUtils/components/componentsLibrary/tabs/Tabs';
import { Input } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/permissions/handlePermissions';
import PermissionDeniedPage from 'ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';
import PermissionFadeContainer from 'ck_commun/src/app/modUtils/components/permissionFadeContainer/PermissionFadeContainer';

export default function TransactionsCentralizedInSale({ paymentList }) {
    const { TabPane } = Tabs;
    const { t } = useTranslation();
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_support_centralized:show_support_centralized',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_support_centralized:show_support_centralized',
        groupPermissions,
    );

    const tabsToRender = paymentList?.map((payment, index) => {
        const dataPayment = [
            {
                label: 'transactionsCentralizedInSale.amountRequested',
                value: amountFormatWithCurrencySymbol(
                    payment?.amount_requested,
                ),
            }, // string amount
            {
                label: 'transactionsCentralizedInSale.amountApplied',
                value: amountFormatWithCurrencySymbol(payment?.amount_applied),
            }, // string amount
            {
                label: 'transactionsCentralizedInSale.date',
                value: dateFormat(payment?.date),
            }, // date
            {
                label: 'entity_profil_through',
                value: payment?.entity_profil_through,
            }, // int
            {
                label: 'table.tabColEquipment',
                value: t(eEquipmentType[payment?.equipment_type]),
            }, // int
            {
                label: 'transactionsCentralizedInSale.id',
                value: payment?.id,
            }, // int
            {
                label: 'sale',
                value: payment?.sale,
            }, // int
            {
                label: 'table.tabColSite',
                value: payment?.site_name,
            }, // string
        ];

        return (
            <TabPane
                label={t('saleDetailsModal.centralizedTransactionSupport')}
                tabKey={`transactions-support-prepayment-tab-${index}`}
                key={`transactions-support-prepayment-tab-${index}`}
            >
                <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 px-9'>
                    {!verifyPermission ? (
                        <PermissionDeniedPage
                            direction='row'
                            permission={verifyGroupPermission}
                        />
                    ) : (
                        <PermissionFadeContainer
                            permission={verifyGroupPermission}
                        >
                            {dataPayment.map((item, index) => (
                                <div key={index} className='col-span-1'>
                                    <span>{t(item.label)}</span>
                                    <Input
                                        className='w-full'
                                        size={30}
                                        disabled
                                        value={item.value}
                                    />
                                </div>
                            ))}
                        </PermissionFadeContainer>
                    )}
                </div>
            </TabPane>
        );
    });

    return tabsToRender?.length > 0 ? (
        <Tabs classNameTabContent='bg-secondary-color-50 mt-4'>
            {tabsToRender}
        </Tabs>
    ) : null;
}
