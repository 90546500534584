import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { eCdbsPaymentType } from 'ck_commun/src/commun_global/enums';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import { get_sale_detail_by_group } from 'ck_commun/src/app/modSale/slices/saleSlice';
import { Modal } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { Input } from 'ck_commun/src/app/modUtils/components/componentsLibrary/componentsLibrary';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';
import TransactionsBankingInSale from 'ck_commun/src/app/modBanking/components/TransactionsBankingInSale';
import TransactionsLocalInSale from 'ck_commun/src/app/modWalletLocal/components/TransactionsLocalInSale';
import TransactionsPrepaymentInSale from 'ck_commun/src/app/modSupportPrepayment/components/TransactionsPrepaymentInSale';
import TransactionsCentralizedInSale from 'ck_commun/src/app/modSupportCentralized/components/TransactionsCentralizedInSale';

export default function SaleDetailsModal({ closeMenu, saleId, closeModal }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { saleDetails } = useSelector((state) => state.sale);
    const { groupId } = useSelector((state) => state.manager);
    const [modalVisible, setModalVisible] = useState(false);
    const [loadingSaleDetails, setLoadingSaleDetails] = useState(true);
    const [transactionsLocalInSale, setTransactionsLocalInSale] = useState([]);
    const [transactionsBankingInSale, setTransactionsBankingInSale] = useState(
        [],
    );
    const [transactionsCentralizedInSale, setTransactionsCentralizedInSale] =
        useState([]);
    const [transactionsPrepaymentInSale, setTransactionsPrepaymentInSale] =
        useState([]);

    const fetchData = async () => {
        setLoadingSaleDetails(true);
        const response = await dispatch(
            get_sale_detail_by_group({ groupId, saleId }),
        );
        closeMenu && closeMenu();

        loadingSaleDetails && setModalVisible(true);
        setLoadingSaleDetails(false);
        setTransactionsLocalInSale(
            response?.payload?.transactions_support_local,
        );
        setTransactionsBankingInSale(response?.payload?.transactions_banking);
        setTransactionsPrepaymentInSale(
            response?.payload?.transactions_support_prepayment,
        );
        setTransactionsCentralizedInSale(
            response?.payload?.transactions_support_centralized,
        );
    };

    useEffect(() => {
        fetchData();
    }, [groupId, i18next.language]);

    const onCloseModal = () => {
        closeModal();
    };

    const getTd = (value, className = '') => {
        return (
            <td
                className={`truncate w-full border-solid border-collapse border-[1px]
                    border-gray-300 text-gray-600 px-2 align-middle text-sm h-6
                    ${className}
                `}
            >
                {value}
            </td>
        );
    };

    const generateRetailRows = () =>
        saleDetails?.retails?.map((retail) => (
            <tr key={retail?.id}>
                {getTd(retail?.label_local, 'w-1/2')}
                {getTd(`* ${retail?.quantity}`, 'w-1/4')}
                {getTd(
                    amountFormatWithCurrencySymbol(retail?.price_include_vat),
                    'w-1/4 text-right',
                )}
            </tr>
        )) || [];

    const generatePaymentRows = () =>
        saleDetails?.payments?.map((payment) => (
            <tr key={payment?.id}>
                {getTd(t(eCdbsPaymentType[payment?.type_payment]), 'w-1/2')}
                {getTd(
                    `
                    ${payment?.count > 1 ? payment?.count + ' * ' : ''}
                    ${amountFormatWithCurrencySymbol(payment?.unit)}
                `,
                    'w-1/4',
                )}
                {getTd(amountFormatWithCurrencySymbol(payment.amount), 'w-1/4')}
            </tr>
        )) || [];

    const balanceRows = (rows1, rows2) => {
        const maxRowCount = Math.max(rows1.length, rows2.length);

        // Clonage des tableaux pour éviter de modifier les originaux
        const balancedRows1 = [...rows1];
        const balancedRows2 = [...rows2];

        // Ajout de lignes vides si nécessaire
        while (balancedRows1.length < maxRowCount) {
            balancedRows1.push(
                <tr key={`emptyRetailRow${balancedRows1.length + 1}`}>
                    {getTd(null, 'w-1/2')}
                    {getTd(null, 'w-1/4')}
                    {getTd(null, 'w-1/4 text-right')}
                </tr>,
            );
        }

        while (balancedRows2.length < maxRowCount) {
            balancedRows2.push(
                <tr key={`emptyPaymentRow${balancedRows2.length + 1}`}>
                    {getTd(null, 'w-1/2')}
                    {getTd(null, 'w-1/4')}
                    {getTd(null, 'w-1/4 text-right')}
                </tr>,
            );
        }

        return [balancedRows1, balancedRows2];
    };
    const retailRows = generateRetailRows();
    const paymentRows = generatePaymentRows();
    const [balancedRetailRows, balancedPaymentRows] = balanceRows(
        retailRows,
        paymentRows,
    );

    const detailsSale = [
        { label: 'saleDetailsModal.saleID', value: saleDetails?.id },
        {
            label: 'saleDetailsModal.transactionDate',
            value: dateFormat(saleDetails?.date_probable),
        },
        { label: 'saleDetailsModal.siteName', value: saleDetails?.site },
        {
            label: 'saleDetailsModal.equipmentName',
            value: saleDetails?.equipment?.name,
        },
        {
            label: 'saleDetailsModal.hardware',
            value:
                saleDetails?.equipment?.hardware_id &&
                `${saleDetails?.equipment?.hardware_id} / ${saleDetails?.equipment?.hardware_version}`,
        },

        {
            label: 'saleDetailsModal.totalIn',
            value: amountFormatWithCurrencySymbol(
                saleDetails?.price_total_real,
            ),
        },
        {
            label: 'saleDetailsModal.free',
            value: amountFormatWithCurrencySymbol(saleDetails?.amount_free),
        },
        {
            label: 'saleDetailsModal.paidIncludingTax',
            value: amountFormatWithCurrencySymbol(
                saleDetails?.amount_paid_currency_subject_vat,
            ),
        },
        {
            label: 'saleDetailsModal.vat',
            value: amountFormatWithCurrencySymbol(saleDetails?.amount_paid_vat),
        },
        {
            label: 'saleDetailsModal.transferred',
            value: amountFormatWithCurrencySymbol(
                saleDetails?.amount_paid_intermediate,
            ),
        },
    ];

    // Division du tableau pour chaque section de la grille
    const grid2Columns = detailsSale.slice(0, 2); // Les 2 premiers éléments
    const grid3Columns = detailsSale.slice(2, 5); // Les 3 éléments suivants
    const grid5Columns = detailsSale.slice(5); // Les 5 derniers éléments

    return (
        <Modal
            title={t('saleDetailsModal.transactionDetails')}
            isOpen={modalVisible}
            onClose={onCloseModal}
            width='70%'
            height='90%'
        >
            {!loadingSaleDetails && (
                <>
                    <div className='border border-solid border-primary-color rounded bg-primary-light-color p-4'>
                        <div className='mx-5 mb-4 flex justify-between'>
                            {grid2Columns.map(({ label, value }) => (
                                <div>
                                    <span>{t(label)}</span> :
                                    <span className='px-2'>{value}</span>
                                </div>
                            ))}
                        </div>
                        <div className='grid lg:grid-cols-3 gap-4'>
                            {grid3Columns.map(({ label, value }) => (
                                <div>
                                    <span>{t(label)}</span>
                                    <Input
                                        className='w-full'
                                        size={30}
                                        disabled
                                        value={value}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className='grid lg:grid-cols-5 gap-4'>
                            {grid5Columns.map(({ label, value }) => (
                                <div>
                                    <span>{t(label)}</span>
                                    <Input
                                        className='w-full'
                                        size={30}
                                        disabled
                                        value={value}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div
                        className={`grid grid-cols-1 md:grid-cols-7 gap-4 max-h-[300px] overflow-auto
                        sale-details-tables mt-4 mb-6 mr-0 ml-0
                    `}
                    >
                        <div className='col-span-1 md:col-span-3'>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th className='w-1/2 text-left font-bold text-sm border-none'>
                                            {t(
                                                'saleDetailsModal.productsPurchased',
                                            )}
                                        </th>
                                        <th className='w-1/4 text-left font-bold text-sm border-none'>
                                            {t('saleDetailsModal.quantity')}
                                        </th>
                                        <th className='w-1/4 text-left font-bold text-sm border-none'>
                                            {t('saleDetailsModal.total')}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>{balancedRetailRows}</tbody>
                            </table>
                        </div>

                        <div className='col-span-1 flex justify-center items-center'>
                            {saleDetails.has_error_balance ? (
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/unequalIcon.png'
                                    }
                                    alt='img'
                                />
                            ) : (
                                <img
                                    src={
                                        process.env.PUBLIC_URL +
                                        '/img/equalIcon.png'
                                    }
                                    alt='img'
                                />
                            )}
                        </div>

                        <div className='col-span-1 md:col-span-3'>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <th className='w-1/2 text-left font-bold text-sm border-none'>
                                            {t(
                                                'saleDetailsModal.paymentMethods',
                                            )}
                                        </th>
                                        <th className='w-1/4 text-left font-bold text-sm border-none'>
                                            {t('saleDetailsModal.detail')}
                                        </th>
                                        <th className='w-1/4 text-left font-bold text-sm border-none'>
                                            {t('saleDetailsModal.total')}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>{balancedPaymentRows}</tbody>
                            </table>
                        </div>
                    </div>

                    <TransactionsBankingInSale
                        paymentList={transactionsBankingInSale}
                    />

                    <TransactionsLocalInSale
                        paymentList={transactionsLocalInSale}
                    />

                    <TransactionsPrepaymentInSale
                        paymentList={transactionsPrepaymentInSale}
                    />

                    <TransactionsCentralizedInSale
                        paymentList={transactionsCentralizedInSale}
                    />
                </>
            )}
        </Modal>
    );
}
