import { useTranslation } from 'react-i18next';
import Button from '../../../modUtils/components/buttons/Button';
import { IoIosArrowForward } from 'react-icons/io';

const NavigationButtons = ({
    current,
    steps,
    onNext,
    onPrev,
    handleSubmit,
}) => {
    const { t } = useTranslation();
    return (
        <div className='flex-display align-items-center gap-12 justify-content-center'>
            {current > 0 && (
                <Button
                    className={
                        'font-bold w-auto shadow-none my-2 flex-display align-items-center gap-2'
                    }
                    color='primary'
                    onClick={onPrev}
                >
                    {t('createPromotionModal.transPreviousButton')}
                </Button>
            )}
            {current < steps.length && (
                <Button
                    type={'button'}
                    className={
                        'font-bold w-auto shadow-none my-2 flex-display align-items-center gap-2'
                    }
                    color='success'
                    onClick={onNext}
                >
                    {t('createPromotionModal.transNextButton')}{' '}
                    <IoIosArrowForward />
                </Button>
            )}
            {current === steps.length && (
                <Button
                    type='submit'
                    className={
                        'font-bold w-auto shadow-none my-2 flex-display align-items-center gap-2'
                    }
                    color='success'
                    onClick={handleSubmit}
                >
                    {t('createPromotionModal.transValidateButton')}
                </Button>
            )}
        </div>
    );
};

export default NavigationButtons;
