import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Modal,
    Spinner,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import './ModalCreatePromotion.scss';
import { message, Steps } from 'antd';
import { Formik } from 'formik';
import NavigationButtons from './NavigationButtons';
import { steps } from '../../assets/js/steps';
import { StepperScreen } from './StepperScreen';
import { days, defaultValues } from '../../assets/js/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
    create_promotion,
    update_promotion,
} from '../../slices/promotionSlice';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { stepValidationSchemas } from './Schema';
import { removeNullFields } from '../../../modUtils/functions/values/removeNulFields';
import _ from 'lodash'; // Import lodash for deep comparison

const { Step } = Steps;

const ModalCreatePromotion = ({
    groupId,
    setModalCreatePromotion,
    modalCreatePromotionOpen,
    promotionId,
    initialValues,
    setInitialValues,
    mode,
    setPromotionsData,
    setPromotionId,
    setOffset,
    offset,
    setMode,
}) => {
    const dispatch = useDispatch();
    const { loadingDetail } = useSelector((state) => state.promotion);
    const [current, setCurrent] = useState(0);
    const { t } = useTranslation();
    const [last, setLast] = useState(false);

    useEffect(() => {
        if (current > 3) {
            setLast(true);
        }
    }, [current]);

    const onNext = async (validateForm, values) => {
        try {
            const errors = await validateForm();
            if (Object.keys(errors).length === 0) {
                setCurrent((prev) =>
                    current === 0 && !values.typePromo ? prev + 2 : prev + 1,
                );
            } else if (current === 1 && !values.enableObtainPromo) {
                setCurrent((prev) => prev + 1);
            }
        } catch (error) {
            console.error('Validation error:', error);
        }
    };

    const prev = (values) => {
        if (last) setLast(false);
        setCurrent(
            (prev) => prev - (current === 2 && !values.typePromo ? 2 : 1),
        );
    };

    const showSuccess = (messageKey) => {
        message.success({
            content: (
                <AlertMessage status={'success'} alertMessage={t(messageKey)} />
            ),
        });
    };

    const showError = (error, messageKey) => {
        message.error({
            content: (
                <AlertMessage
                    status={error?.status}
                    alertMessage={t(messageKey)}
                    errorDetail={error?.message}
                />
            ),
        });
    };

    const handleSubmit = async (values) => {
        let updatedFields = {};
        if (promotionId && mode === 'update') {
            Object.keys(values).forEach((key) => {
                if (!_.isEqual(values[key], initialValues[key])) {
                    updatedFields[key] = values[key];
                }
            });
        }
        const data =
            promotionId && mode === 'update'
                ? removeNullFields({
                      name: updatedFields.promotionName,
                      description: updatedFields.description,
                      promotion_type: updatedFields.typePromo,
                      amount: updatedFields.amountUsePromo,
                      amount_promo_type: updatedFields.discountAmount,
                      criteria_obtaining_is_active:
                          updatedFields.enableObtainPromo,
                      criteria_obtaining:
                          Object.keys(
                              removeNullFields({
                                  date_start:
                                      updatedFields.startDateOfferObtainPromo,
                                  date_end:
                                      updatedFields.endDateOfferObtainPromo,
                                  amount_min: updatedFields.minObtainPromo,
                                  amount_max: updatedFields.maxObtainPromo,
                                  site_ids: updatedFields.sitesObtainPromo,
                                  equipment_types:
                                      updatedFields.equipementsTypeObtainPromo,
                                  schedule_criteria: updatedFields
                                      .constraintObtainPromo?.length
                                      ? updatedFields.constraintObtainPromo
                                      : null,
                              }),
                          ).length && values.typePromo
                              ? removeNullFields({
                                    date_start:
                                        updatedFields.startDateOfferObtainPromo,
                                    date_end:
                                        updatedFields.endDateOfferObtainPromo,
                                    amount_min: updatedFields.minObtainPromo,
                                    amount_max: updatedFields.maxObtainPromo,
                                    site_ids: updatedFields.sitesObtainPromo,
                                    equipment_types:
                                        updatedFields.equipementsTypeObtainPromo,

                                    schedule_criteria: updatedFields
                                        .constraintObtainPromo?.length
                                        ? updatedFields.constraintObtainPromo
                                        : null,
                                })
                              : null,
                      criteria_usage: Object.keys(
                          removeNullFields({
                              date_start: updatedFields.startDateOfferUsePromo,
                              date_end: updatedFields.endDateOfferUsePromo,
                              amount_min: updatedFields.minUsePromo,
                              amount_max: updatedFields.maxUsePromo,
                              site_ids: updatedFields.sitesUsePromo,
                              equipment_types:
                                  updatedFields.equipementsTypeUsePromo,
                              selection_ids: updatedFields.idsUsePromo
                                  ?.split(',')
                                  .map(Number),
                              count_usage_max:
                                  updatedFields.maximumPrintingUsePromo ===
                                  'illimited'
                                      ? null
                                      : updatedFields.maximumPrintingUsePromo ===
                                          '1'
                                        ? 1
                                        : updatedFields.maximumPrintingUsePromo,
                              lifetime_start_days:
                                  updatedFields.startDayValidityUsePromo,
                              lifetime: updatedFields.endDayValidityUsePromo,
                              schedule_criteria: updatedFields
                                  .constraintUsePromo?.length
                                  ? updatedFields.constraintUsePromo
                                  : null,
                          }),
                      ).length
                          ? removeNullFields({
                                date_start:
                                    updatedFields.startDateOfferUsePromo,
                                date_end: updatedFields.endDateOfferUsePromo,
                                amount_min: updatedFields.minUsePromo,
                                amount_max: updatedFields.maxUsePromo,
                                site_ids: updatedFields.sitesUsePromo,
                                equipment_types:
                                    updatedFields.equipementsTypeUsePromo,
                                selection_ids: updatedFields.idsUsePromo
                                    ?.split(',')
                                    .map(Number),
                                count_usage_max:
                                    updatedFields.maximumPrintingUsePromo ===
                                    'illimited'
                                        ? null
                                        : updatedFields.maximumPrintingUsePromo ===
                                            '1'
                                          ? 1
                                          : updatedFields.maximumPrintingUsePromo,
                                lifetime_start_days:
                                    updatedFields.startDayValidityUsePromo,
                                lifetime: updatedFields.endDayValidityUsePromo,
                                schedule_criteria: updatedFields
                                    .constraintUsePromo?.length
                                    ? updatedFields.constraintUsePromo
                                    : null,
                            })
                          : null,
                      // ticket_template_sms: updatedFields.ticket_template_sms,
                      // ticket_template_email: updatedFields.ticket_template_email,
                      ticket_template_paper:
                          updatedFields.ticket_template_paper,
                  })
                : removeNullFields({
                      name: values.promotionName,
                      description: values.description,
                      promotion_type: values.typePromo,
                      amount: values.amountUsePromo,
                      amount_promo_type: values.discountAmount,
                      criteria_obtaining_is_active: values.enableObtainPromo,
                      criteria_obtaining:
                          Object.keys(
                              removeNullFields({
                                  date_start: values.startDateOfferObtainPromo,
                                  date_end: values.endDateOfferObtainPromo,
                                  amount_min: values.minObtainPromo,
                                  amount_max: values.maxObtainPromo,
                                  site_ids: values.sitesObtainPromo,
                                  equipment_types:
                                      values.equipementsTypeObtainPromo,
                                  count_distribution_max:
                                      values.maximumPrintingObtainPromo,
                                  schedule_criteria: values
                                      .constraintObtainPromo?.length
                                      ? values.constraintObtainPromo
                                      : null,
                              }),
                          ).length && values.typePromo
                              ? removeNullFields({
                                    date_start:
                                        values.startDateOfferObtainPromo,
                                    date_end: values.endDateOfferObtainPromo,
                                    amount_min: values.minObtainPromo,
                                    amount_max: values.maxObtainPromo,
                                    site_ids: values.sitesObtainPromo,
                                    equipment_types:
                                        values.equipementsTypeObtainPromo,
                                    count_distribution_max:
                                        values.maximumPrintingObtainPromo,
                                    schedule_criteria: values
                                        .constraintObtainPromo?.length
                                        ? values.constraintObtainPromo
                                        : null,
                                })
                              : null,
                      criteria_usage: Object.keys(
                          removeNullFields({
                              date_start: values.startDateOfferUsePromo,
                              date_end: values.endDateOfferUsePromo,
                              amount_min: values.minUsePromo,
                              amount_max: values.maxUsePromo,
                              site_ids: values.sitesUsePromo,
                              equipment_types: values.equipementsTypeUsePromo,
                              selection_ids: values.idsUsePromo
                                  ?.split(',')
                                  .map(Number),
                              count_usage_max:
                                  values.maximumPrintingUsePromo === 'illimited'
                                      ? null
                                      : values.maximumPrintingUsePromo === '1'
                                        ? 1
                                        : values.maximumPrintingUsePromo,
                              lifetime_start_days:
                                  values.startDayValidityUsePromo,
                              lifetime: values.endDayValidityUsePromo,
                              schedule_criteria: values.constraintUsePromo
                                  ?.length
                                  ? values.constraintUsePromo
                                  : null,
                          }),
                      ).length
                          ? removeNullFields({
                                date_start: values.startDateOfferUsePromo,
                                date_end: values.endDateOfferUsePromo,
                                amount_min: values.minUsePromo,
                                amount_max: values.maxUsePromo,
                                site_ids: values.sitesUsePromo,
                                equipment_types: values.equipementsTypeUsePromo,
                                selection_ids: values.idsUsePromo
                                    ?.split(',')
                                    .map(Number),
                                count_usage_max:
                                    values.maximumPrintingUsePromo ===
                                    'illimited'
                                        ? null
                                        : values.maximumPrintingUsePromo === '1'
                                          ? 1
                                          : values.maximumPrintingUsePromo,
                                lifetime_start_days:
                                    values.startDayValidityUsePromo,
                                lifetime: values.endDayValidityUsePromo,
                                schedule_criteria: values.constraintUsePromo
                                    ?.length
                                    ? values.constraintUsePromo
                                    : null,
                            })
                          : null,
                      // ticket_template_sms: values.ticket_template_sms,
                      // ticket_template_email: values.ticket_template_email,
                      ticket_template_paper: values.ticket_template_paper,
                  });

        try {
            const action =
                promotionId && mode === 'update'
                    ? update_promotion({
                          groupId,
                          promotionId,
                          data,
                      })
                    : create_promotion({ groupId, data });

            const response = await dispatch(action).unwrap();

            if (response) {
                setOffset(0); // Reset offset first
                setPromotionsData([]); // Reset promotions data
                const successMessageKey =
                    promotionId && mode === 'update'
                        ? 'success.transSuccessUpdatePromotion'
                        : promotionId && mode === 'duplicate'
                          ? 'success.transSuccessDuplicatePromotion'
                          : 'success.transSuccessCreatePromotion';
                showSuccess(successMessageKey);
            }
        } catch (error) {
            const errorMessageKey =
                promotionId && mode === 'update'
                    ? 'errors.transErrorUpdatePromotion'
                    : promotionId && mode === 'duplicate'
                      ? 'success.transErrorDuplicatePromotion'
                      : 'errors.transErrorCreatePromotion';
            showError(error, errorMessageKey);
        }
    };

    useEffect(() => {
        if (offset === 0) {
            toggleModal();
        }
    }, [offset]);

    useEffect(() => {
        if (mode === 'create') setInitialValues(defaultValues);
    }, [mode]);

    const toggleModal = () => {
        setCurrent(0);
        setLast(false);
        setPromotionId(null);
        setInitialValues(defaultValues);
        setMode('create');
        setModalCreatePromotion(false);
    };

    const modalStyles = {
        modalTitle: {
            color: '#25292D',
            fontSize: '20px',
            lineHeight: '23px',
            marginBottom: 0,
        },
        modalBody: { height: '100%' },
        modalContent: {
            backgroundColor: (() => {
                if (current === 1) return '#D6E8FD';
                if (current === 2 && mode !== 'update') return '#FCF6F0';
                return '#fff';
            })(),
        },
    };

    return (
        <Formik
            validationSchema={stepValidationSchemas[current]}
            onSubmit={(values) => handleSubmit(values)}
            initialValues={initialValues}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize={true}
        >
            {({
                values,
                errors,
                touched,
                validateForm,
                handleChange,
                handleSubmit,
                setFieldValue,
            }) => (
                <>
                    {modalCreatePromotionOpen && (
                        <Modal
                            isOpen={modalCreatePromotionOpen}
                            onClose={toggleModal}
                            title={
                                current === 0
                                    ? promotionId
                                        ? mode === 'duplicate'
                                            ? t('transDuplicatePromotionTitle')
                                            : t('transButtionUpdate')
                                        : t(
                                              'createPromotionModal.transTitleModal',
                                          )
                                    : current === 1
                                      ? t(
                                            'createPromotionModal.stepperCreatePromotion.transPromotionPrintingCondition',
                                        )
                                      : current === 2
                                        ? t(
                                              'createPromotionModal.stepperCreatePromotion.transConditionUsePromotion',
                                          )
                                        : current === 3
                                          ? t(
                                                'createPromotionModal.stepperCreatePromotion.transTicketFormat',
                                            )
                                          : current === 4
                                            ? t(
                                                  'createPromotionModal.stepperCreatePromotion.transValidation',
                                              )
                                            : t(
                                                  'createPromotionModal.transTitleModal',
                                              )
                            }
                            className='modalCreatePromotion'
                            width={'80rem'}
                            height={'50rem'}
                            style={modalStyles}
                        >
                            {loadingDetail ? (
                                <Spinner />
                            ) : (
                                <div className='h-full'>
                                    <form
                                        onSubmit={handleSubmit}
                                        className='flex flex-col justify-content-between h-full'
                                    >
                                        <Steps
                                            current={current}
                                            labelPlacement='vertical'
                                            responsive={false}
                                            className='w-1/2 mx-auto sm:w-1/2 mb-4'
                                        >
                                            {steps(t).map((item, index) => (
                                                <Step
                                                    key={item.title}
                                                    title={item.title}
                                                    icon={
                                                        index === current
                                                            ? item.icon
                                                            : index < current
                                                              ? item.successIcon
                                                              : item.waitingIcon
                                                    }
                                                />
                                            ))}
                                        </Steps>
                                        <StepperScreen
                                            last={last}
                                            days={days(t)}
                                            step={current}
                                            initialValues={initialValues}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            mode={mode}
                                        />
                                        {/* {mode === 'update' ? (
                                            <StepperScreenUpdate
                                                last={last}
                                                days={days(t)}
                                                step={current}
                                                initialValues={initialValues}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                            />
                                        ) : (
                                            <StepperScreen
                                                last={last}
                                                days={days(t)}
                                                step={current}
                                                initialValues={initialValues}
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                            />
                                        )} */}
                                        <NavigationButtons
                                            current={current}
                                            steps={steps(t)}
                                            onNext={() =>
                                                onNext(
                                                    validateForm,
                                                    values,
                                                    errors,
                                                )
                                            }
                                            onPrev={() => prev(values)}
                                            handleSubmit={handleSubmit}
                                            values={values}
                                        />
                                    </form>
                                </div>
                            )}
                        </Modal>
                    )}
                </>
            )}
        </Formik>
    );
};

export default ModalCreatePromotion;
