import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { eCdbsPaymentType } from 'ck_commun/src/commun_global/enums';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/permissions/handlePermissions';
import { get_sales_equipment_by_group_paginated } from 'ck_commun/src/app/modSale/slices/saleSlice';
import PermissionDeniedPage from 'ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage';
import { amountFormatWithCurrencySymbol } from 'ck_commun/src/app/modUtils/functions/values/numberFormat';
import EquipmentSaleDetails from 'ck_commun/src/app/modSale/components/equipmentSaleDetails/EquipmentSaleDetails';
import CkTablePaginatedScroll from 'ck_commun/src/app/modUtils/components/componentsLibrary/table/CkTablePaginatedScroll';
import PermissionFadeContainer from 'ck_commun/src/app/modUtils/components/permissionFadeContainer/PermissionFadeContainer';

export default function EquipmentSales({ equipment, isModalVisible }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId, selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission('mod_sale:show_sale', permissions);
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_sale:show_sale',
        groupPermissions,
    );

    const getSalesEquipmentByGroupPaginated = async (limit, offset) => {
        const response = await dispatch(
            get_sales_equipment_by_group_paginated({
                groupId: groupId,
                equipmentId: equipment.id,
                ordering: '-date',
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    const columns = [
        {
            title: t('table.tabColId'),
            dataIndex: 'id',
            key: 'id',
            className: 'w-[5%]',
        },
        {
            title: t('table.tabColDate'),
            dataIndex: 'date_probable',
            key: 'date_probable',
            className: 'w-[15%]',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColProduct'),
            dataIndex: 'retails',
            key: 'retails',
            className: 'w-[10%] max-w-[205px] font-bold text-left truncate',
            render: (text, record) =>
                record.retails
                    ?.map(
                        (retail) =>
                            retail.label_local +
                            '(' +
                            retail.local_id +
                            ') ' +
                            (retail.quantity == 1
                                ? ''
                                : ' x' + retail.quantity),
                    )
                    .join(', '),
        },
        {
            title: t('table.tabColPayment'),
            dataIndex: 'payments',
            key: 'payments',
            className: 'w-[10%] max-w-[182px] font-bold text-left truncate',
            render: (text, record) =>
                record.payments
                    ?.filter(
                        (payment, index, arr) =>
                            arr.findIndex(
                                (p) => p.type_payment === payment.type_payment,
                            ) === index,
                    )
                    .map((payment) => t(eCdbsPaymentType[payment.type_payment]))
                    .join(', '), //filter les payments de même type pour n'afficher le type qu'une seule fois
        },
        {
            title: t('table.tabColAmount'),
            dataIndex: 'price_total_selected',
            key: 'price_total_selected',
            className: 'w-[15%] font-bold text-right',
            render: (text) => (
                <span>{amountFormatWithCurrencySymbol(text)}</span>
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'actions',
            className: 'w-[5%]',
            // TODO pk ne pas utiliser un tooltip au click ?
            render: (text, record) => <EquipmentSaleDetails sale={record} />,
        },
    ];

    return (
        <>
            {!verifyPermission ? (
                <PermissionDeniedPage permission={verifyGroupPermission} />
            ) : (
                <PermissionFadeContainer
                    permission={verifyGroupPermission}
                    opacity={50}
                >
                    {isModalVisible && (
                        <CkTablePaginatedScroll
                            columns={columns}
                            fetch={getSalesEquipmentByGroupPaginated}
                            maxHeight='max-h-[690px]'
                            // dataSource={equipmentSales}
                        />
                    )}
                </PermissionFadeContainer>
            )}
        </>
    );
}
