import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { eFeatureId } from 'ck_commun/src/commun_global/enums';
import { dateFormat } from 'ck_commun/src/app/modUtils/functions/values/dateFormat';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/permissions/handlePermissions';
import PermissionDeniedPage from 'ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage';
import CkTablePaginatedScroll from 'ck_commun/src/app/modUtils/components/componentsLibrary/table/CkTablePaginatedScroll';
import { get_device_histories_by_equipment_by_group_paginated } from 'ck_commun/src/app/modEquipment/slices/equipmentSlice';
import PermissionFadeContainer from 'ck_commun/src/app/modUtils/components/permissionFadeContainer/PermissionFadeContainer';
import './DevicesHistory.scss';

export default function DevicesHistory({ equipment, isModalVisible }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId, selectedGroup } = useSelector((state) => state.manager);

    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_equipment:show_device',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_equipment:show_device',
        groupPermissions,
    );

    const getDeviceHistoriesByEquipmentPaginatedByGroup = async (
        limit,
        offset,
    ) => {
        const response = await dispatch(
            get_device_histories_by_equipment_by_group_paginated({
                groupId: groupId,
                equipmentId: equipment.id,
                limit,
                offset,
            }),
        ).unwrap();
        return response;
    };

    const columns = [
        {
            title: t('table.tabColDate'),
            dataIndex: 'date',
            key: 'date',
            className: 'w-[20%]',
            render: (text) => dateFormat(text),
        },
        {
            title: t('table.tabColDevice'),
            dataIndex: 'feature_id',
            key: 'feature_id',
            className: 'w-[30%] font-bold text-left truncate',
            render: (text, record) => (
                <span>
                    {t(eFeatureId[text])}{' '}
                    {record?.device_id > 1 && record?.device_id}
                </span>
            ),
        },
        {
            title: t('table.tabColStatus'),
            dataIndex: 'status',
            key: 'status',
            className: 'w-[50%] font-bold text-left',
            render: (text, record) => (
                <div className='flex items-center'>
                    <span
                        className={`codeEllips ${text == 4 ? 'codeOk' : 'codeError'}`}
                    ></span>
                    <span className='ml-2.5'>
                        {record.code_label}
                        {record.code != 0 && `(${record.code})`}
                    </span>
                </div>
            ),
        },
    ];

    return (
        <>
            {!verifyPermission ? (
                <PermissionDeniedPage permission={verifyGroupPermission} />
            ) : (
                <PermissionFadeContainer
                    permission={verifyGroupPermission}
                    opacity={50}
                >
                    {isModalVisible && (
                        <CkTablePaginatedScroll
                            columns={columns}
                            fetch={
                                getDeviceHistoriesByEquipmentPaginatedByGroup
                            }
                            maxHeight='max-h-[690px]'
                        />
                    )}
                </PermissionFadeContainer>
            )}
        </>
    );
}
