import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { httpGet, httpPost, httpPut } from '../../modApi/apis/httpConfig';

export const get_products_by_group_paginated = createAsyncThunk(
    'mod_box/get_products_by_group_paginated',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_box/groups/${values.groupId}/products/`,
                {
                    params: {
                        limit: values.limit,
                        offset: values.offset,
                    },
                },
            );

            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

export const get_products_by_group_detail = createAsyncThunk(
    'mod_box/get_products_by_group_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpGet(
                `mod_box/groups/${values.groupId}/products/${values.productId}/`,
            );

            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

export const update_products_by_group_detail = createAsyncThunk(
    'mod_box/update_products_by_group_detail',
    async (values, { rejectWithValue }) => {
        try {
            const response = await httpPut(
                `mod_box/groups/${values.groupId}/products/${values.productId}/`,
                {
                    information_caution: values.informationCaution,
                    quantity_default: values.defaultQuantity,
                    is_product_editable: values.isEditable,
                    is_price_editable: values.isPriceEditable,
                    expired_date_is_used: values.isExpiryDate,
                    expired_date_day_number: values.expiredDateNumber,
                    short_date_is_used: values.isShortDate,
                    short_date_day_number: values.shortDateNum,
                    short_date_discount: values.shortDateDiscount,
                    products_child_quantity: {},
                    item: {
                        is_public: values.item.isPublic,
                        is_package: values.item.isPackage,
                        name: values.item.productName,
                        label: values.item.productBrand,
                        description: values.item.productDesc,
                        image: values.item.productImage,
                        price_by_unit: values.item.priceByUnit,
                        currency: values.item.currency,
                        cab: values.item.productCab,
                        measure_type: values.item.measureType,
                        stock_available: values.item.stockAvailable,
                        supply_threshold: values.item.supplyThreshold,
                        supply_threshold_quantity:
                            values.item.supplyThresholdQuantity,
                        vat_rate_id: values.item.vatRateId,
                    },
                },
            );
            return response.data;
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

export const create_products_by_group = createAsyncThunk(
    'mox_box/create_products_by_group',
    async (values, { rejectWithValue }) => {
        console.log(values);
        try {
            const response = await httpPost(
                `mod_box/groups/${values.groupId}/products/`,
                {
                    information_caution: values.informationCaution,
                    quantity_default: values.defaultQuantity,
                    is_product_editable: values.isEditable,
                    is_price_editable: values.isPriceEditable,
                    expired_date_is_used: values.isExpiryDate,
                    expired_date_day_number: values.expiredDateNumber,

                    short_date_day_number: values.shortDateNum,
                    short_date_discount: values.shortDateDiscount,

                    item: {
                        is_public: values.item.isPublic,

                        name: values.item.productName,
                        label: values.item.productBrand,
                        description: values.item.productDesc,

                        price_by_unit: values.item.priceByUnit,
                        currency: values.item.currency,

                        measure_type: values.item.measureType,
                        stock_available: values.item.stockAvailable,
                        supply_threshold: values.item.supplyThreshold,
                        supply_threshold_quantity:
                            values.item.supplyThresholdQuantity,
                        vat_rate_id: values.item.vatRateId,
                    },
                },
            );
        } catch (error) {
            return rejectWithValue({
                status: error.response.status,
                data: error.response.data,
            });
        }
    },
);

const boxSlice = createSlice({
    name: 'box',
    initialState: {
        products: [],
        productDetails: [],
        productsCount: 0,
    },
    reducers: {
        setProductDetails: (state, action) => ({ ...state, ...action.payload }),
        updateField: (state, action) => {
            state[action.payload.field] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        builder
            /* get_products_by_group_paginated */

            .addCase(
                get_products_by_group_paginated.pending,
                (state, action) => {
                    state.products = [];
                    state.productsCount = 0;
                },
            )

            .addCase(
                get_products_by_group_paginated.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.products = action.payload.results;
                    state.productsCount = action.payload.count;
                },
            )
            .addCase(
                get_products_by_group_paginated.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )

            /* get_products_by_group_detail */

            .addCase(get_products_by_group_detail.pending, (state, action) => {
                state.productDetails = [];
            })

            .addCase(
                get_products_by_group_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.productDetails.push(action.payload);
                },
            )

            .addCase(get_products_by_group_detail.rejected, (state, action) => {
                state.loading = false;
            })

            /* update_products_by_group_detail */
            .addCase(
                update_products_by_group_detail.pending,
                (state, action) => {
                    state.productDetails = [];
                },
            )

            .addCase(
                update_products_by_group_detail.fulfilled,
                (state, action) => {
                    state.loading = false;
                    state.productDetails = {
                        ...state.productDetails,
                        ...action.payload,
                        item: {
                            ...state.productDetails.item,
                            ...action.payload.item,
                        },
                    };
                },
            )

            .addCase(
                update_products_by_group_detail.rejected,
                (state, action) => {
                    state.loading = false;
                },
            )
            .addCase(create_products_by_group.pending, (state, action) => {
                state.productDetails = [];
            })
            .addCase(create_products_by_group.fulfilled, (state, action) => {
                state.loading = false;
                state.productDetails = {
                    ...state.productDetails,
                    ...action.payload,
                    item: {
                        ...state.productDetails.item,
                        ...action.payload?.item,
                    },
                };
            })

            .addCase(create_products_by_group.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const { setProductDetails, updateField } = boxSlice.actions;

export default boxSlice.reducer;
