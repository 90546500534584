import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Card,
    Input,
    Modal,
    Textarea,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import AlertMessage from '../../../modUtils/components/AlertMessage';
import { Checkbox, message, Select } from 'antd';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import {
    create_products_by_group,
    get_products_by_group_detail,
    update_products_by_group_detail,
} from '../../slices/boxSlice';
import ButtonAdd from '../../../modUtils/components/buttons/ButtonAdd';
import {
    get_sale_vat_paginated,
    update_items_image,
} from '../../../modSaleManagement/slices/saleManagementSlice';
import eCurrency from '../../../../commun_global/js/payment/e_currency';

export default function ModifyProductModal({
    modalTitle,
    mode,
    productId,
    modalVisible,
    setModalVisible,
}) {
    const { t } = useTranslation();
    const { groupId } = useSelector((store) => store.manager);
    const dispatch = useDispatch();
    const { productDetails } = useSelector((store) => store.box);
    const { vatRates } = useSelector((store) => store.saleManagement);
    const [image, setImage] = useState();
    const [initialFormState, setInitialFormState] = useState({});
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);

    const [formState, setFormState] = useState({
        informationCaution: '',
        defaultQuantity: '',
        isEditable: '',
        isPriceEditable: '',
        isExpiryDate: '',
        expiredDateNumber: '',
        isShortDate: '',
        shortDateNum: '',
        shortDateDiscount: '',
        item: {
            isPublic: '',
            isPackage: '',
            productName: '',
            productBrand: '',
            productDesc: '',
            productImage: '',
            priceByUnit: '',
            currency: '',
            measureType: '',
            stockAvailable: '',
            supplyThreshold: '',
            supplyThresholdQuantity: '',
            vatRateId: '',
            vatRate: '',
        },
    });

    useEffect(() => {
        if (groupId && productId) {
            getProductDetails({ groupId, productId });
        }
        if (groupId) getVatRates(0, 10);
    }, [groupId]);

    const measureTypes = [
        { value: 0, label: 'Quantity' },
        { value: 1, label: 'Poids' },
        { value: 2, label: 'Volume' },
        { value: 3, label: 'Longueur' },
    ];

    const currencyTypes = Object.keys(eCurrency).map((key) => ({
        value: Number(key),
        label: t(`currency.${eCurrency[key].split('.').pop()}`),
    }));

    console.log(formState?.item?.currency);

    const vateRateDetails = vatRates?.map((rate) => {
        return { value: rate.id, label: rate.vat_rate };
    });

    const handleOnSelectCurrency = (value) => {
        const selectedCurrency = currencyTypes?.find(
            (item) => item.value === Number(value),
        );
        console.log(selectedCurrency);

        setFormState((prevState) => ({
            ...prevState,
            item: {
                ...prevState.item,
                currency: selectedCurrency.value,
            },
        }));
    };

    const handleOnSelectVat = (value) => {
        const selectedVat = vateRateDetails.find(
            (item) => item.value === value,
            setFormState((prevState) => ({
                ...prevState,
                item: {
                    ...prevState.item,
                    vatRateId: selectedVat.value,
                    vatRate: selectedVat.label,
                },
            })),
        );
    };

    const handleDropdownChange = (value) => {
        const selectedOption = measureTypes?.find(
            (item) => item.value === value,
        );
        setFormState((prevState) => ({
            ...prevState,
            item: {
                ...prevState.item,
                measureType: selectedOption.value,
            },
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleNestedInputChange = (e) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            item: {
                ...prevState.item,
                [name]: value,
            },
        }));
    };

    const handleExpiryToggle = (value) => {
        setFormState((prevState) => ({
            ...prevState,
            isExpiryDate: value,
            expiredDateNumber:
                value == false ? null : formState.expiredDateNumber,
        }));
    };

    const handleShortDateToggle = (value) => {
        setFormState((prevState) => ({
            ...prevState,
            isShortDate: value,
            shortDateNum: value == false ? null : formState.shortDateNum,
            shortDateDiscount:
                value == false ? '' : formState.shortDateDiscount,
        }));
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormState((prevState) => ({
            ...prevState,
            [name]: checked,
            item: {
                ...prevState.item,
                [name]: checked, // Update the checkbox state
            },
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setImage(file);
        }
    };

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        if (productDetails.length > 0) {
            const initialData = {
                informationCaution:
                    productDetails[0]?.information_caution || '',
                defaultQuantity: productDetails[0]?.quantity_default || 0,
                isEditable: productDetails[0]?.is_product_editable || false,
                isPriceEditable: productDetails[0]?.is_price_editable || false,
                isExpiryDate: productDetails[0]?.expired_date_is_used || false,
                expiredDateNumber:
                    productDetails[0]?.expired_date_day_number || null,
                isShortDate: productDetails[0]?.short_date_is_used || false,
                shortDateNum: productDetails[0]?.short_date_day_number || null,
                shortDateDiscount:
                    productDetails[0]?.short_date_discount || null,
                item: {
                    isPublic: productDetails[0]?.item?.is_public || false,
                    isPackage: productDetails[0]?.item?.is_package || false,
                    productName: productDetails[0]?.item?.name || '',
                    productBrand: productDetails[0]?.item?.label || '',
                    productDesc: productDetails[0]?.item?.description || '',
                    productImage: productDetails[0]?.item?.image || '',
                    priceByUnit: productDetails[0]?.item?.price_by_unit || '0',
                    currency: productDetails[0]?.item?.currency || '0',
                    measureType: productDetails[0]?.item?.measure_type || '0',
                    stockAvailable:
                        productDetails[0]?.item?.stock_available || '',
                    supplyThreshold:
                        productDetails[0]?.item?.supply_threshold || '',
                    supplyThresholdQuantity:
                        productDetails[0]?.item?.supply_threshold_quantity ||
                        '',
                    vatRate: productDetails[0]?.item?.vat_rate || '0',
                },
            };
            setFormState(initialData);
            setInitialFormState(initialData);
        }
    }, [productDetails]);

    const onReset = () => {
        setModalVisible(false);
    };
    const getProductDetails = async () => {
        try {
            const response = await dispatch(
                get_products_by_group_detail({
                    groupId,
                    productId,
                }),
            ).unwrap();
        } catch (error) {
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('product.error')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        }
    };

    const updateImage = async () => {
        try {
            const response = await dispatch(
                update_items_image({
                    groupId,
                    itemId: productDetails[0]?.item?.id,
                    image: image,
                }),
            ).unwrap();
        } catch (error) {
            console.log(error);
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('product.error')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
            setLoading(false);
        }
    };

    const getVatRates = async (offset, limit) => {
        try {
            const response = await dispatch(
                get_sale_vat_paginated({
                    groupId,
                    limit,
                    offset,
                }),
            ).unwrap();
        } catch (error) {
            console.log(error);
            message.error({
                content: (
                    <AlertMessage
                        status={error?.status}
                        alertMessage={t('product.error')}
                        errorDetail={error?.data?.detail}
                    />
                ),
            });
        }
    };

    const getChangedValues = () => {
        const changedValues = {};

        Object.keys(formState).forEach((key) => {
            if (typeof formState[key] === 'object' && formState[key] !== null) {
                // Handle nested object (e.g., formState.item)
                Object.keys(formState[key]).forEach((subKey) => {
                    if (
                        formState[key][subKey] !== initialFormState[key][subKey]
                    ) {
                        if (!changedValues[key]) changedValues[key] = {};
                        changedValues[key][subKey] = formState[key][subKey];
                    }
                });
            } else if (formState[key] !== initialFormState[key]) {
                changedValues[key] = formState[key];
            }
        });

        return changedValues;
    };
    const handleOnOk = async () => {
        const updatedValues = getChangedValues();
        if (mode === 'update') {
            try {
                setLoading(true);

                const response = await dispatch(
                    update_products_by_group_detail({
                        groupId,
                        productId: productId,
                        ...updatedValues,
                    }),
                ).unwrap();
                if (image) updateImage();
                setLoading(false);
                setModalVisible(false);
            } catch (error) {
                console.log(error);
                message.error({
                    content: (
                        <AlertMessage
                            status={error?.status}
                            alertMessage={t('product.error')}
                            errorDetail={error?.data?.detail}
                        />
                    ),
                });
                setLoading(false);
            }
        } else if (mode === 'create') {
            try {
                setLoading(true);
                const response = await dispatch(
                    create_products_by_group({
                        groupId,
                        ...updatedValues,
                    }),
                ).unwrap();
                if (image) updateImage();
                setLoading(false);
                setModalVisible(false);
            } catch (error) {
                console.log(error);
                message.error({
                    content: (
                        <AlertMessage
                            status={error?.status}
                            alertMessage={t('product.error')}
                            errorDetail={error?.data?.detail}
                        />
                    ),
                });
                setLoading(false);
            }
        }
    };

    return (
        <>
            <Modal
                title={modalTitle}
                isOpen={modalVisible}
                onClose={onReset}
                onOk={handleOnOk}
                style={{
                    modalContent: {
                        minWidth: '300px',

                        minHeight: '700px',
                        width: '65%',
                        height: '85%',
                    },
                }}
            >
                <Form form={form} layout='vertical'>
                    {' '}
                    {modalVisible && (
                        <div className=' grid grid-cols-12  gap-4 '>
                            <div className='col-span-4 h-full'>
                                {/* Product Image */}
                                <Card className='min-h-96 '>
                                    <div className='flex items-start m-2 font-semibold'>
                                        {t('viewModal.cardTitle1')}
                                    </div>
                                    <div className='flex flex-col items-center justify-center my-6'>
                                        <Card className=' h-40 w-64'>
                                            <img
                                                src={
                                                    formState?.item
                                                        ?.productImage
                                                }
                                                alt='No image'
                                            ></img>
                                        </Card>
                                        <div className='mt-4 w-64'>
                                            {t('viewModal.imageSubtitle')}
                                        </div>
                                        <ButtonAdd
                                            className='m-4 '
                                            label={t('viewModal.addImageBtn')}
                                            onClick={handleButtonClick}
                                        ></ButtonAdd>
                                        <input
                                            type='file'
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                            accept='image/*'
                                        ></input>
                                    </div>
                                </Card>
                                <Card className='my-4 h-[430px]'>
                                    <span className='flex items-start m-2 font-semibold'>
                                        {t('viewModal.cardTitle3')}
                                    </span>
                                    <div className='flex'>
                                        <Form.Item>
                                            <Checkbox
                                                name='isPackage'
                                                checked={
                                                    formState.item.isPackage
                                                }
                                                onChange={handleCheckboxChange}
                                            >
                                                <span>
                                                    {t(
                                                        'viewModal.checkboxLabel',
                                                    )}
                                                </span>
                                            </Checkbox>
                                        </Form.Item>
                                    </div>
                                    <span className='flex items-start'>
                                        {t('viewModal.listLabel')}
                                    </span>
                                    {productDetails[0]?.item?.item_child_links.map(
                                        (item) => (
                                            <Card className='my-2 h-16'>
                                                <div className='flex items-center'>
                                                    <span className='w-36 font-semibold'>
                                                        {item?.item_child_name}
                                                    </span>
                                                    <span className=' ml-16 font-semibold text-sm text-[--destructive-color]'>
                                                        {t(
                                                            'viewModal.quantityLabel',
                                                        )}
                                                        {item?.quantity}
                                                    </span>
                                                </div>
                                            </Card>
                                        ),
                                    )}
                                    <div className='flex flex-col items-center justify-center my-6'>
                                        <ButtonAdd
                                            label={t('viewModal.addImageBtn')}
                                        />
                                    </div>
                                </Card>

                                {/*Stock details */}

                                <Card className='my-4 h-80'>
                                    <span className='flex items-start m-2 font-semibold'>
                                        {t('productStock.title')}
                                    </span>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        <Input
                                            name='stockAvailable'
                                            label={t('productStock.quantity')}
                                            value={
                                                formState.item.stockAvailable
                                            }
                                            className='m-2'
                                            onChange={handleNestedInputChange}
                                        />
                                    </Form.Item>

                                    <Form.Item style={{ marginBottom: 0 }}>
                                        <Input
                                            name='supplyThreshold'
                                            label={t('productStock.threshold')}
                                            value={
                                                formState.item.supplyThreshold
                                            }
                                            className='m-2'
                                            onChange={handleNestedInputChange}
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        <Input
                                            name='supplyThresholdQuantity'
                                            label={t(
                                                'productStock.defaultQuantity',
                                            )}
                                            value={
                                                formState.item
                                                    .supplyThresholdQuantity
                                            }
                                            className='m-2'
                                            onChange={handleNestedInputChange}
                                        />
                                    </Form.Item>
                                </Card>
                            </div>

                            {/* Column 2 */}
                            <div className='col-span-8 h-full'>
                                {/*  General Info */}
                                <Card>
                                    <span className='flex items-start m-2 font-semibold'>
                                        {t('viewModal.cardTitle2')}
                                    </span>
                                    <div className='grid grid-cols-2 gap-4'>
                                        <div className='relative'>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Input
                                                    name='productName'
                                                    label={t(
                                                        'product.nameLabel',
                                                    )}
                                                    value={
                                                        formState.item
                                                            .productName
                                                    }
                                                    onChange={
                                                        handleNestedInputChange
                                                    }
                                                />
                                            </Form.Item>

                                            <span className='absolute italic right-2 bottom-[-6px] text-xs text-gray-500'>
                                                {t('viewModal.inputSubtext')}
                                            </span>
                                        </div>

                                        <div className='relative'>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Input
                                                    name='productBrand'
                                                    label={t(
                                                        'product.brandLabel',
                                                    )}
                                                    value={
                                                        formState.item
                                                            .productBrand
                                                    }
                                                    onChange={
                                                        handleNestedInputChange
                                                    }
                                                />
                                            </Form.Item>

                                            <span className='absolute italic right-2 bottom-[-6px] text-xs text-gray-500'>
                                                {t('viewModal.inputSubtext')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='flex-col text-left'>
                                        {' '}
                                        <div className='relative mt-6'>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Textarea
                                                    name='productDesc'
                                                    label={t(
                                                        'product.descriptionLabel',
                                                    )}
                                                    rows={5}
                                                    className='text-left mt-2'
                                                    value={
                                                        formState.item
                                                            .productDesc
                                                    }
                                                    onChange={
                                                        handleNestedInputChange
                                                    }
                                                />
                                            </Form.Item>

                                            <span className='absolute italic right-3 bottom-[-10px] text-xs text-gray-500'>
                                                {t('viewModal.textareaSubtext')}
                                            </span>
                                        </div>
                                        <div className='relative my-6'>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Textarea
                                                    name='informationCaution'
                                                    label={t(
                                                        'product.warningLabel',
                                                    )}
                                                    rows={5}
                                                    className='text-left mt-2'
                                                    value={
                                                        formState.informationCaution
                                                    }
                                                    onChange={handleInputChange}
                                                />
                                            </Form.Item>

                                            <span className='absolute italic right-3 bottom-[-10px] text-xs text-gray-500'>
                                                {t('viewModal.textareaSubtext')}
                                            </span>
                                        </div>
                                    </div>
                                </Card>

                                {/*   Detail information */}
                                <Card className='my-4 h-80'>
                                    <span className='flex items-start m-2 font-semibold'>
                                        {t('viewModal.cardTitle4')}
                                    </span>
                                    <Form.Item className='flex'>
                                        <Checkbox
                                            name='isPriceEditable'
                                            checked={formState.isPriceEditable}
                                            onChange={handleCheckboxChange}
                                        >
                                            <span>
                                                {t(
                                                    'productDetails.checkboxLabel1',
                                                )}
                                            </span>
                                        </Checkbox>
                                    </Form.Item>
                                    <div className='grid grid-cols-3'>
                                        <Form.Item className='flex items-start '>
                                            <Select
                                                placeholder='Type de measure'
                                                style={{ width: 130 }}
                                                value={[
                                                    measureTypes[
                                                        Number(
                                                            formState?.item
                                                                ?.measureType,
                                                        )
                                                    ].label,
                                                ]}
                                                disabled={
                                                    formState.isPriceEditable
                                                }
                                                options={measureTypes}
                                                onChange={(value) =>
                                                    handleDropdownChange(value)
                                                }
                                            ></Select>
                                        </Form.Item>
                                        <Form.Item>
                                            <Select
                                                placeholder='Taux TVA'
                                                style={{ width: 130 }}
                                                options={vateRateDetails}
                                                value={[
                                                    vateRateDetails.find(
                                                        (item) =>
                                                            item.label ===
                                                            formState?.item
                                                                ?.vatRate,
                                                    )?.label,
                                                ]}
                                                onChange={(value) =>
                                                    handleOnSelectVat(value)
                                                }
                                            ></Select>
                                        </Form.Item>
                                        <Form.Item>
                                            <Select
                                                placeholder='Devise'
                                                style={{ width: 180 }}
                                                options={currencyTypes}
                                                value={[
                                                    currencyTypes.find(
                                                        (item) =>
                                                            item.value ===
                                                            formState?.item
                                                                ?.currency,
                                                    )?.label,
                                                ]}
                                                onChange={(value) =>
                                                    handleOnSelectCurrency(
                                                        value,
                                                    )
                                                }
                                            ></Select>
                                        </Form.Item>
                                    </div>

                                    <Form.Item className='flex'>
                                        <Checkbox
                                            name='isEditable'
                                            checked={formState.isEditable}
                                            onChange={handleCheckboxChange}
                                            disabled={formState.isPriceEditable}
                                        >
                                            <span>
                                                {t(
                                                    'productDetails.checkBoxLabel2',
                                                )}
                                            </span>
                                        </Checkbox>
                                    </Form.Item>
                                    <div className='grid grid-cols-4 gap-2'>
                                        {' '}
                                        <Form.Item>
                                            <Input
                                                name='priceByUnit'
                                                className='w-40'
                                                label={t(
                                                    'productDetails.priceByUnitLabel',
                                                )}
                                                value={
                                                    formState.item.priceByUnit
                                                }
                                                onChange={
                                                    handleNestedInputChange
                                                }
                                                disabled={
                                                    formState.isPriceEditable
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Input
                                                name='defaultQuantity'
                                                className='w-40'
                                                label={t(
                                                    'productDetails.quantityDefaultLabel',
                                                )}
                                                value={
                                                    formState.defaultQuantity
                                                }
                                                onChange={handleInputChange}
                                                disabled={
                                                    formState.isPriceEditable
                                                }
                                            />
                                        </Form.Item>
                                        {!formState.isEditable && (
                                            <Form.Item>
                                                <Input
                                                    name='totalPrice'
                                                    className='w-40'
                                                    value={
                                                        formState.item
                                                            .priceByUnit *
                                                        formState.defaultQuantity
                                                    }
                                                    label={t(
                                                        'productDetails.totalPriceLabel',
                                                    )}
                                                    disabled={
                                                        formState.isPriceEditable
                                                    }
                                                />
                                            </Form.Item>
                                        )}
                                    </div>
                                </Card>

                                {/* Consumption details */}
                                <Card>
                                    {' '}
                                    <span className='flex items-start m-2 font-semibold'>
                                        {t('productPerishable.cardTitle')}
                                    </span>
                                    <div className='grid grid-cols-3 gap-4'>
                                        <div className='flex items-center gap-2'>
                                            <Checkbox name='isPerishable'>
                                                {t(
                                                    'productPerishable.checkboxLabel',
                                                )}
                                            </Checkbox>
                                        </div>

                                        <Input
                                            name='expiredDateNumber'
                                            className='w-36'
                                            label={t(
                                                'productPerishable.inputLabel',
                                            )}
                                            value={formState.expiredDateNumber}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className='flex items-start my-2'>
                                        {t('productprice.shortDateLabel')}
                                    </div>
                                    <div className='grid grid-cols-12 gap-4 my-2'>
                                        <div className='relative col-span-5'>
                                            <div className='relative'>
                                                <button
                                                    className={`px-6 py-2 rounded-md flex items-center gap-2 ${
                                                        !formState.isShortDate
                                                            ? 'var(--card-foreground-color)'
                                                            : 'bg-transparent'
                                                    }`}
                                                    onClick={() =>
                                                        handleShortDateToggle(
                                                            false,
                                                        )
                                                    }
                                                >
                                                    {/* Radio button inside the button, with purple color */}
                                                    <input
                                                        type='radio'
                                                        className='h-4 w-4 var(--primary-light-color)'
                                                        checked={
                                                            !formState.isShortDate
                                                        }
                                                    />
                                                    {t(
                                                        'productprice.radioBtnLabel1',
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <div className='relative col-span-7'>
                                            <div className='relative'>
                                                <button
                                                    className={`px-6 py-2 rounded-md flex items-center gap-2 ${
                                                        formState.isShortDate
                                                            ? 'var(--card-foreground-color)'
                                                            : 'bg-transparent'
                                                    }`}
                                                    onClick={() =>
                                                        handleShortDateToggle(
                                                            true,
                                                        )
                                                    }
                                                >
                                                    {/* Radio button inside the button, with purple color */}
                                                    <input
                                                        type='radio'
                                                        className='h-4 w-4 var(--primary-light-color) '
                                                        checked={
                                                            formState.isShortDate
                                                        }
                                                    />
                                                    {t(
                                                        'productprice.radioBtnLabel2',
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>{' '}
                                    <div className='grid grid-cols-3 gap-4 my-4'>
                                        <div className='relative'>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                {' '}
                                                <Input
                                                    name='shortDateNum'
                                                    label={t(
                                                        'productprice.durationLabel',
                                                    )}
                                                    disabled={
                                                        !formState.isShortDate
                                                    }
                                                    value={
                                                        formState.isShortDate ==
                                                        true
                                                            ? formState.shortDateNum
                                                            : ''
                                                    }
                                                    onChange={handleInputChange}
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className='relative'>
                                            <Form.Item
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Input
                                                    name='shortDateDiscount'
                                                    label={t(
                                                        'productprice.percentageLabel',
                                                    )}
                                                    disabled={
                                                        !formState.isShortDate
                                                    }
                                                    value={
                                                        formState.isShortDate ==
                                                        true
                                                            ? formState.shortDateDiscount
                                                            : ''
                                                    }
                                                    onChange={handleInputChange}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
}
