// on set la variable IS_PROD a true quand le site est en ligne , on la passe a false uniquement si on est sur l'url de dev
// je prefère la mettre a true par défaut pour éviter les risques

const URL_DEV = 'https://dev.v3.ck-wash.fr';
const URL_PROD = 'https://v3.ck-wash.fr';

const SRC_IMG = process.env.PUBLIC_URL + '/img/menuIcons/';
// const APP_LOGO = process.env.PUBLIC_URL + "/ckwashLogo.png";
const APP_LOGO = process.env.PUBLIC_URL + '/ckwashLogo.svg';
const APP_NAME = 'CkWash Manager';
const APP_SHORT_NAME = 'CkWash';

const APP_NAME_CODE = 'CKWASH_MANAGER';
const CK_SERVER_DEV_PASSWORD = 'hFsIEUfwZqbdkfpdajeVLCW591Tubh2r';
const CK_SERVER_PROD_PASSWORD = '2hsAxbItRj9aLSGEi69ygrKwboAtKhmw';

const CONTACT_PHONE_NUMBER = '0 473 289 246';
const HOMEPAGE_ROUTE = '/home';
const FOOTER_LOGINPAGE_VISIBLE = true;

const URL_MOBILE_APPLE =
    'https://apps.apple.com/fr/app/ckwash-manager/id6477200533';
const URL_MOBILE_ANDROID =
    'https://play.google.com/store/apps/details?id=fr.cksquare.ckwash_manager';

export {
    APP_NAME,
    APP_NAME_CODE,
    APP_LOGO,
    APP_SHORT_NAME,
    CK_SERVER_DEV_PASSWORD,
    CK_SERVER_PROD_PASSWORD,
    CONTACT_PHONE_NUMBER,
    FOOTER_LOGINPAGE_VISIBLE,
    HOMEPAGE_ROUTE,
    URL_DEV,
    URL_PROD,
    SRC_IMG,
    URL_MOBILE_APPLE,
    URL_MOBILE_ANDROID,
};
