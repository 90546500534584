import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import Tabs, {
    TabPane,
} from '../../../modUtils/components/componentsLibrary/tabs/Tabs';
import EquipmentSales from '../../../modSale/components/equipmentSales/EquipmentSales';
import EquipmentEventsHistory from '../equipmentEventsHistory/EquipmentEventsHistory';
import DevicesHistory from '../devicesHistory/DevicesHistory';
import EquipmentCredit from '../../../modFixing/components/equipmentCredit/EquipmentCredit';
import PermissionDeniedPage from 'ck_commun/src/app/modUtils/components/errorPage/PermissionDeniedPage';
import { hasPermission } from 'ck_commun/src/app/modUtils/functions/permissions/handlePermissions';
import { useTranslation } from 'react-i18next';
import FormRepay from 'ck_commun/src/app/modSupportPrepayment/components/FormRepay';
import { IS_PROD } from '../../../modUtils/utils/constants';

export default function ModalFixingEquipment({
    equipment,
    siteId,
    isModalVisible,
    setIsModalVisible,
}) {
    const [openTabLeft, setOpenTabLeft] = useState('1');
    const [openTabRight, setOpenTabRight] = useState('6');
    const { selectedGroup } = useSelector((state) => state.manager);
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_equipment:can_fixing',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_equipment:can_fixing',
        groupPermissions,
    );
    const { t } = useTranslation();

    const changeOpentab = (activeTab) => {
        setOpenTabLeft(activeTab);
    };

    return (
        <>
            <Modal
                title={t('equipmentFixingModal.title')}
                isOpen={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                width={1300}
            >
                {!verifyPermission ? (
                    <PermissionDeniedPage permission={verifyGroupPermission} />
                ) : (
                    <div className='flex-display flex-row justify-content-around'>
                        <div style={{ width: '65%' }}>
                            <Tabs
                                onChange={(e, key) => changeOpentab(e, key)}
                                headerMatchesBodyWidth
                                tabHeight={710}
                                openTab={openTabLeft}
                            >
                                <TabPane
                                    label={t('equipmentFixingModal.saleLabel')}
                                    tabKey='1'
                                >
                                    <EquipmentSales
                                        equipment={equipment}
                                        isModalVisible={isModalVisible}
                                    />
                                </TabPane>
                                <TabPane
                                    label={t('equipmentFixingModal.eventLabel')}
                                    tabKey='2'
                                >
                                    <EquipmentEventsHistory
                                        equipment={equipment}
                                        isModalVisible={isModalVisible}
                                    />
                                </TabPane>
                                <TabPane
                                    label={t(
                                        'equipmentFixingModal.deviceLabel',
                                    )}
                                    tabKey='3'
                                >
                                    <DevicesHistory
                                        equipment={equipment}
                                        isModalVisible={isModalVisible}
                                    />
                                </TabPane>
                                {/* <TabPane label="CAMERAS" tabKey="4">
                        <BuildingPage />
                    </TabPane> */}
                            </Tabs>
                        </div>
                        <div style={{ width: '33%' }}>
                            <Tabs
                                onChange={(key) => setOpenTabRight(key)}
                                headerMatchesBodyWidth
                                tabHeight={710}
                                openTab={openTabRight}
                            >
                                <TabPane
                                    disabled={IS_PROD}
                                    label={t(
                                        'equipmentFixingModal.refundLabel',
                                    )}
                                    tabKey='5'
                                >
                                    <FormRepay equipment={equipment} />
                                </TabPane>

                                <TabPane
                                    label={t(
                                        'equipmentFixingModal.creditEquipment',
                                    )}
                                    tabKey='6'
                                >
                                    <EquipmentCredit
                                        equipment={equipment}
                                        siteId={siteId}
                                        isModalVisible={isModalVisible}
                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
}
