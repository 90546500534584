import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { create_access_link } from '../../slices/equipmentSlice';
import { hasPermission } from '../../../modUtils/functions/permissions/handlePermissions';
import ButtonDisabled from '../../../modUtils/components/buttons/ButtonDisabled';
import Button from '../../../modUtils/components/buttons/Button';
import CKToolTip from '../../../modUtils/components/CKToolTip/CKToolTip';

export default function EquipmentConnectionToServerButton({
    buttonType,
    equipment,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { groupId, selectedGroup } = useSelector((state) => state.manager);
    const connexionToServerIsConfigured = equipment?.connection_is_configured;
    const permissions = selectedGroup?.permissions;
    const verifyPermission = hasPermission(
        'mod_equipment:can_access',
        permissions,
    );
    const groupPermissions = selectedGroup?.plan?.permissions;
    const verifyGroupPermission = hasPermission(
        'mod_equipment:can_access',
        groupPermissions,
    );
    const [url, setUrl] = useState(null);
    const [clickButtonServer, setClickButtonServer] = useState(false);

    const handleConnectionToServer = async () => {
        setClickButtonServer(true);
        if (url) {
            window.open(url);
        } else {
            try {
                // Dispatch the create_access_link API call to create the access link
                const response = await dispatch(
                    create_access_link({
                        groupId,
                        equipmentId: equipment.id,
                    }),
                );
                setUrl(response.payload.url);
                window.open(response.payload.url);
            } catch (error) {
                console.error('Error handling connection to server:', error);
            }
        }
        setClickButtonServer(false);
    };

    return (
        <>
            {buttonType === 'button' ? (
                connexionToServerIsConfigured &&
                verifyPermission &&
                verifyGroupPermission ? (
                    <Button
                        onClick={!clickButtonServer && handleConnectionToServer}
                        color='primary'
                        className='h-10 w-full whitespace-nowrap uppercase'
                        size='md'
                    >
                        {t('equipment.transLocalServerWebCnxButton')}
                    </Button>
                ) : (
                    <CKToolTip
                        placement='topLeft'
                        content={t('machineCard.connectionNotConfigured')}
                        trigger={!connexionToServerIsConfigured && 'hover'}
                    >
                        <ButtonDisabled
                            onClick={
                                connexionToServerIsConfigured &&
                                verifyPermission &&
                                !clickButtonServer
                                    ? handleConnectionToServer
                                    : null
                            }
                            className='h-10 w-full whitespace-nowrap mx-[5px] uppercase'
                            size='sm'
                        >
                            {t('equipment.transLocalServerWebCnxButton')}
                        </ButtonDisabled>
                    </CKToolTip>
                )
            ) : (
                <CKToolTip
                    placement='topLeft'
                    content={t('machineCard.connectionNotConfigured')}
                    trigger={!connexionToServerIsConfigured && 'hover'}
                >
                    <p
                        onClick={
                            connexionToServerIsConfigured &&
                            verifyPermission &&
                            !clickButtonServer
                                ? handleConnectionToServer
                                : (e) => e.preventDefault()
                        }
                        className={
                            connexionToServerIsConfigured &&
                            verifyPermission &&
                            verifyGroupPermission
                                ? 'text-card-foreground-color'
                                : '!text-muted-foreground-color bg-muted-color cursor-not-allowed'
                        }
                    >
                        {t('equipment.transLocalServerWebCnxButton')}
                    </p>
                </CKToolTip>
            )}
        </>
    );
}
