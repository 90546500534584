import React, { useState } from 'react';
import ButtonAdd from '../../../modUtils/components/buttons/ButtonAdd';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../modUtils/components/componentsLibrary/componentsLibrary';

export default function AddNewProduct() {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState(false);
    return (
        <>
            <div className='flex flex-col text-xl items-center text-center'>
                <div>{t('product.addProductLabel')}</div>
                <ButtonAdd
                    label={t('product.labelBtn')}
                    onClick={() => setModalVisible(true)}
                    className=' mt-4'
                />
                <Modal
                    title={t('Ajouter un nouveau produit')}
                    isOpen={modalVisible}
                ></Modal>
            </div>
        </>
    );
}
