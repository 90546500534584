import {
    Select,
    Input,
    Table,
} from '../../../modUtils/components/componentsLibrary/componentsLibrary';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import DayCard from '../dayCard/DayCard';
import { useSelector } from 'react-redux';
import { eEquipmentType } from '../../../../commun_global/enums';
import RangePicker from '../../../modUtils/components/componentsLibrary/datePicker/RangePicker';
import Button from '../../../modUtils/components/buttons/Button';
import { CgAddR } from 'react-icons/cg';
import { IoClose } from 'react-icons/io5';
import RepeaterTime from '../repeaterTime/RepeaterTime';
import { columns } from '../../assets/js/utils';
import CheckboxInput from '../../../modUtils/components/componentsLibrary/checkbox/Checkbox';
import moment from 'moment';
import TooltipLabel from '../../../modUtils/components/tooltipLabel/TooltipLabel';
import { renderError } from '../../../modUtils/functions/values/renderError';
import ButtonDisabled from '../../../modUtils/components/buttons/ButtonDisabled';

const SecondStepperScreen = ({
    values,
    handleChange,
    days,
    setFieldValue,
    errors,
    initialValues,
    mode,
}) => {
    const { t } = useTranslation();
    const { sites } = useSelector((state) => state.manager);
    const { equipmentTypeGroup } = useSelector((state) => state.equipment);
    const [addNewContraint, setAddNewContraint] = useState(false);
    const [startDate, setStartDate] = useState(
        initialValues.startDateOfferObtainPromo
            ? moment.unix(initialValues.startDateOfferObtainPromo).toDate()
            : null,
    );
    const [endDate, setEndDate] = useState(
        initialValues.endDateOfferObtainPromo
            ? moment.unix(initialValues.endDateOfferObtainPromo).toDate()
            : null,
    );
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [schedulesList, setSchedulesList] = useState([]);
    const [selectedDays, setSelectedDays] = useState({
        schedule_monday: false,
        schedule_tuesday: false,
        schedule_wednesday: false,
        schedule_thursday: false,
        schedule_friday: false,
        schedule_saturday: false,
        schedule_sunday: false,
    });
    const [timeRanges, setTimeRanges] = useState([
        {
            // Mettre un identifiant pour chaque time range pour assurer que chaque élement a un clé unique `key={range.id}` et pour eviter les erreurs et les conflits aussi pour mettre le même retour dans le mode update
            id: 0,
            start: moment('00:00', 'HH:mm'),
            end: moment('23:59', 'HH:mm'),
        },
    ]);

    const saveSchedule = () => {
        let updatedSchedulesList = [...schedulesList]; // Declare the updated list outside

        if (addNewContraint) {
            if (selectedSchedule) {
                // Editing an existing schedule
                const updatedSchedule = {
                    ...selectedSchedule,
                    ...selectedDays, // Update selected days
                    time_slots: timeRanges.map(({ start, end }) => ({
                        slot_hours_start: start ? start.format('HH:mm') : null,
                        slot_hours_end: end ? end.format('HH:mm') : null,
                    })), // Update time slots
                };

                updatedSchedulesList = updatedSchedulesList.map((schedule) =>
                    schedule === selectedSchedule ? updatedSchedule : schedule,
                );
            } else {
                // Creating a new schedule
                const newSchedule = {
                    ...selectedDays,
                    time_slots: timeRanges.map(({ start, end }) => ({
                        slot_hours_start: start ? start.format('HH:mm') : null,
                        slot_hours_end: end ? end.format('HH:mm') : null,
                    })),
                };

                updatedSchedulesList = [...updatedSchedulesList, newSchedule];
            }

            // Directly update `setSchedulesList` and `setFieldValue` with the updated list
            setSchedulesList(updatedSchedulesList);
            setFieldValue('constraintObtainPromo', updatedSchedulesList);
        }

        // Reset states
        setSelectedDays({
            schedule_monday: false,
            schedule_tuesday: false,
            schedule_wednesday: false,
            schedule_thursday: false,
            schedule_friday: false,
            schedule_saturday: false,
            schedule_sunday: false,
        });
        setTimeRanges([
            {
                id: 0,
                start: moment('00:00', 'HH:mm'),
                end: moment('23:59', 'HH:mm'),
            },
        ]);
        setSelectedSchedule(null); // Reset selection
        setAddNewContraint((prevState) => !prevState);
    };

    const deleteSchedule = (scheduleToDelete) => {
        setSchedulesList((prev) =>
            prev.filter((schedule) => schedule !== scheduleToDelete),
        );
    };

    const toggleAllDays = (e) => {
        const isChecked = e.target.checked;

        setSelectedDays({
            schedule_monday: isChecked,
            schedule_tuesday: isChecked,
            schedule_wednesday: isChecked,
            schedule_thursday: isChecked,
            schedule_friday: isChecked,
            schedule_saturday: isChecked,
            schedule_sunday: isChecked,
        });
    };

    const style = {
        td: {
            padding: 0,
        },
    };

    const toggleDaySelection = (day) => {
        setSelectedDays((prev) => ({
            ...prev,
            [day]: !prev[day], // Bascule entre true/false
        }));
    };

    const handleRangeChange = (start, end) => {
        // Fonction pour gérer le changement de plage de dates
        if (start) {
            setStartDate(start);
            setFieldValue('startDateOfferObtainPromo', moment(start).unix());
        }
        if (end) {
            setEndDate(end);
            setFieldValue('endDateOfferObtainPromo', moment(end).unix());
        }
    };

    const handleEditSchedule = (schedule) => {
        setSelectedSchedule(schedule);

        // Set selected days
        setSelectedDays({
            schedule_monday: schedule.schedule_monday,
            schedule_tuesday: schedule.schedule_tuesday,
            schedule_wednesday: schedule.schedule_wednesday,
            schedule_thursday: schedule.schedule_thursday,
            schedule_friday: schedule.schedule_friday,
            schedule_saturday: schedule.schedule_saturday,
            schedule_sunday: schedule.schedule_sunday,
        });

        // Set time slots
        setTimeRanges(
            schedule.time_slots.map((slot, index) => ({
                id: index,
                start: moment(slot.slot_hours_start, 'HH:mm'),
                end: moment(slot.slot_hours_end, 'HH:mm'),
            })),
        );
        setAddNewContraint(true);
    };

    const onSelect = (e, name) => {
        const currentValues = values[name] || [];
        setFieldValue(name, [...currentValues, e]);
    };

    const onDeselect = (e, name) => {
        const newSitesData = values[name].filter((site) => site !== e);
        setFieldValue(name, newSitesData);
    };

    return (
        <div className='SecondStepperScreen'>
            <div className='flex-display justify-content-center'>
                <div className='w-full flex-display flex-column align-items-center'>
                    <div className='flex-display align-items-baseline justify-content-center gap-1'>
                        <CheckboxInput
                            errors={errors}
                            onChange={(e) =>
                                setFieldValue(
                                    'enableObtainPromo',
                                    e.target.checked,
                                )
                            }
                            name={'enableObtainPromo'}
                            value={'enableObtainPromo'}
                            checked={values.enableObtainPromo}
                            id={'enableObtainPromo'}
                            label={t(
                                'createPromotionModal.stepperCreatePromotion.transEnableDisablePrinting',
                            )}
                        />
                        <TooltipLabel
                            tooltipMessage={t(
                                'createPromotionModal.stepperCreatePromotion.transEnableDisablePrintingTooltipMessage',
                            )}
                        />
                    </div>
                    {values.enableObtainPromo ? (
                        <div className={`mt-4`}>
                            <div className='flex-col border border-[#4D4D4D42] border-solid py-2.5 px-6 sm:px-12 rounded-[5px] sm:w-max w-full mb-4 mx-auto gap-4'>
                                <TooltipLabel
                                    label={t(
                                        'createPromotionModal.stepperCreatePromotion.transOfferValidity',
                                    )}
                                    tooltipMessage={t(
                                        'createPromotionModal.stepperCreatePromotion.transEnableDisablePrintingTooltipMessage',
                                    )}
                                />
                                <RangePicker
                                    format={'DD/MM/YYYY'}
                                    onRangeChange={handleRangeChange}
                                    className={'flex items-center gap-2'}
                                    color={'neutral'}
                                    errorStartDate={renderError(
                                        t,
                                        errors,
                                        'startDateOfferObtainPromo',
                                    )}
                                    errorEndDate={renderError(
                                        t,
                                        errors,
                                        'endDateOfferObtainPromo',
                                    )}
                                    startLabel={t(
                                        'createPromotionModal.stepperCreatePromotion.transFrom',
                                    )}
                                    endLabel={t(
                                        'createPromotionModal.stepperCreatePromotion.transTo',
                                    )}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                />
                            </div>
                            <Row className='gap-2 w-full justify-around'>
                                <Col
                                    lg={5}
                                    md={24}
                                    sm={24}
                                    sx={24}
                                    className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                                >
                                    <div className='w-full checkbox-block'>
                                        <TooltipLabel
                                            label={t(
                                                'createPromotionModal.stepperCreatePromotion.transOffer',
                                            )}
                                            tooltipMessage={t(
                                                'createPromotionModal.stepperCreatePromotion.transOfferTooltipMessage',
                                            )}
                                        />
                                        <Input
                                            type={'number'}
                                            min={0}
                                            label={t(
                                                'createPromotionModal.stepperCreatePromotion.transMaximumUse',
                                            )}
                                            onChange={handleChange}
                                            placeholder={t(
                                                `createPromotionModal.stepperCreatePromotion.transUnlimited`,
                                            )}
                                            name='maximumPrintingObtainPromo'
                                            value={
                                                values.maximumPrintingObtainPromo
                                            }
                                            id='maximumPrintingObtainPromo'
                                            width={'100%'}
                                            className={`w-full`}
                                            classnames={'h-12 w-full'}
                                            inputMode='numeric'
                                            pattern='\d*'
                                            error={renderError(
                                                t,
                                                errors,
                                                'maximumPrintingObtainPromo',
                                            )}
                                        />
                                    </div>
                                </Col>
                                <Col
                                    lg={5}
                                    md={6}
                                    sm={24}
                                    sx={24}
                                    className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                                >
                                    <TooltipLabel
                                        label={t(
                                            'createPromotionModal.stepperCreatePromotion.transWhere',
                                        )}
                                        tooltipMessage={t(
                                            'createPromotionModal.stepperCreatePromotion.transWhereToolitipMessage',
                                        )}
                                    />
                                    <div className='flex flex-col gap-4'>
                                        <div>
                                            <Select
                                                multiple
                                                error={renderError(
                                                    t,
                                                    errors,
                                                    'sitesObtainPromo',
                                                )}
                                                onSelect={(value) =>
                                                    onSelect(
                                                        value,
                                                        'sitesObtainPromo',
                                                    )
                                                }
                                                onDeselect={(value) =>
                                                    onDeselect(
                                                        value,
                                                        'sitesObtainPromo',
                                                    )
                                                }
                                                style={{
                                                    selectLabel: {
                                                        color: '#25292D',
                                                    },
                                                }}
                                                selectLabel={t(
                                                    'createPromotionModal.stepperCreatePromotion.transSite',
                                                )}
                                                containerWidth={'full'}
                                                dropdownMatchSelectWidth
                                                msgDisplayedWhenNoOptions={t(
                                                    'createPromotionModal.stepperCreatePromotion.transNoData',
                                                )}
                                                defaultValue={
                                                    values.sitesObtainPromo
                                                }
                                                block
                                                name='sitesObtainPromo'
                                                placeholder={t(
                                                    'createPromotionModal.stepperCreatePromotion.transSelect',
                                                )}
                                                options={sites?.map((site) => ({
                                                    value: site.id,
                                                    label: site.name,
                                                }))}
                                                selectedMessage={
                                                    values.sitesObtainPromo
                                                        ?.length
                                                        ? `${values.sitesObtainPromo?.length} ${t(
                                                              'createPromotionModal.stepperCreatePromotion.transSelectedSites',
                                                          )}`
                                                        : t(
                                                              'createPromotionModal.stepperCreatePromotion.transNoSitesSelected',
                                                          )
                                                }
                                            />
                                        </div>
                                        <div>
                                            <Select
                                                multiple
                                                error={renderError(
                                                    t,
                                                    errors,
                                                    'equipementsTypeObtainPromo',
                                                )}
                                                onSelect={(value) =>
                                                    onSelect(
                                                        value,
                                                        'equipementsTypeObtainPromo',
                                                    )
                                                }
                                                onDeselect={(value) =>
                                                    onDeselect(
                                                        value,
                                                        'equipementsTypeObtainPromo',
                                                    )
                                                }
                                                style={{
                                                    selectLabel: {
                                                        color: '#25292D',
                                                    },
                                                }}
                                                selectLabel={t(
                                                    'createPromotionModal.stepperCreatePromotion.transEquipmentType',
                                                )}
                                                containerWidth={'full'}
                                                dropdownMatchSelectWidth
                                                msgDisplayedWhenNoOptions={t(
                                                    'createPromotionModal.stepperCreatePromotion.transNoData',
                                                )}
                                                defaultValue={
                                                    values.equipementsTypeObtainPromo
                                                }
                                                block
                                                name='equipementsTypeObtainPromo'
                                                placeholder={t(
                                                    'createPromotionModal.stepperCreatePromotion.transSelect',
                                                )}
                                                options={equipmentTypeGroup?.map(
                                                    (equipmentType) => ({
                                                        label: t(
                                                            eEquipmentType[
                                                                equipmentType
                                                                    .type
                                                            ],
                                                        ),
                                                        value: equipmentType.type,
                                                    }),
                                                )}
                                                selectedMessage={
                                                    values
                                                        .equipementsTypeObtainPromo
                                                        ?.length
                                                        ? `${values.equipementsTypeObtainPromo?.length} ${t(
                                                              'createPromotionModal.stepperCreatePromotion.transSelectedEquipmentType',
                                                          )}`
                                                        : t(
                                                              'createPromotionModal.stepperCreatePromotion.transNoEquipmentTypeSelected',
                                                          )
                                                }
                                            />
                                        </div>
                                    </div>
                                </Col>

                                {/* TODO : A FIXER PLUS TARD  */}
                                <Col
                                    lg={8}
                                    md={12}
                                    sm={24}
                                    sx={24}
                                    className='border border-[#4D4D4D42] border-solid rounded-[5px]'
                                >
                                    {!addNewContraint ? (
                                        <div className='p-2'>
                                            <TooltipLabel
                                                label={t(
                                                    'createPromotionModal.stepperCreatePromotion.transWhen',
                                                )}
                                                tooltipMessage={t(
                                                    'createPromotionModal.stepperCreatePromotion.transWhenToolitipMessage',
                                                )}
                                            />
                                            <Table
                                                style={style}
                                                columns={columns(
                                                    t,
                                                    handleEditSchedule,
                                                    deleteSchedule,
                                                )}
                                                dataSource={
                                                    values.constraintObtainPromo ||
                                                    []
                                                }
                                                noDataMessage={t(
                                                    'createPromotionModal.stepperCreatePromotion.table.transNoSchedulesData',
                                                )}
                                            />
                                            <div className='flex justify-center mt-4'>
                                                <Button
                                                    color={'thirty'}
                                                    onClick={() =>
                                                        setAddNewContraint(
                                                            (prevState) =>
                                                                !prevState,
                                                        )
                                                    }
                                                    className={
                                                        'font-bold text-xs w-auto shadow-none flex-display align-items-center gap-2'
                                                    }
                                                >
                                                    <CgAddR />{' '}
                                                    {t(
                                                        'createPromotionModal.stepperCreatePromotion.transAddContraint',
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='bg-white rounded h-full'>
                                            <div className='flex justify-end p-2'>
                                                <IoClose
                                                    onClick={() =>
                                                        setAddNewContraint(
                                                            (prevState) =>
                                                                !prevState,
                                                        )
                                                    }
                                                    color='#1B8ADC'
                                                    fontSize={20}
                                                    className='cursor-pointer'
                                                />
                                            </div>
                                            <div className='p-5'>
                                                <div className='flex-display align-items-baseline'>
                                                    <CheckboxInput
                                                        id='everyDay'
                                                        name='days'
                                                        value={'everyDay'}
                                                        checked={Object.values(
                                                            selectedDays,
                                                        ).every(Boolean)}
                                                        onChange={toggleAllDays}
                                                        label={t(
                                                            'createPromotionModal.stepperCreatePromotion.transEveryDay',
                                                        )}
                                                    />
                                                </div>
                                                <div className='flex-display align-items-center justify-content-center gap-2 mt-4'>
                                                    {days &&
                                                        days.length &&
                                                        days.map((day) => (
                                                            <DayCard
                                                                day={day}
                                                                selectDay={() =>
                                                                    toggleDaySelection(
                                                                        day.value,
                                                                    )
                                                                }
                                                                selected={
                                                                    selectedDays[
                                                                        day
                                                                            .value
                                                                    ]
                                                                }
                                                            />
                                                        ))}
                                                </div>
                                            </div>
                                            <div className='p-5 pt-1'>
                                                <RepeaterTime
                                                    startTimeLabel={t(
                                                        'createPromotionModal.stepperCreatePromotion.transFromTime',
                                                    )}
                                                    endTimeLabel={t(
                                                        'createPromotionModal.stepperCreatePromotion.transToTime',
                                                    )}
                                                    setTimeRanges={
                                                        setTimeRanges
                                                    }
                                                    timeRanges={timeRanges}
                                                />
                                            </div>
                                            <div className='flex justify-center mb-4'>
                                                {Object.values(
                                                    selectedDays,
                                                ).some((value) => value) ? (
                                                    <Button
                                                        color={'success'}
                                                        onClick={saveSchedule}
                                                        className={
                                                            'font-bold text-xs'
                                                        }
                                                    >
                                                        {t(
                                                            'createPromotionModal.transValidateButton',
                                                        )}
                                                    </Button>
                                                ) : (
                                                    <ButtonDisabled
                                                        color={'success'}
                                                        className={
                                                            'font-bold text-xs'
                                                        }
                                                    >
                                                        {t(
                                                            'createPromotionModal.transValidateButton',
                                                        )}
                                                    </ButtonDisabled>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </Col>
                                <Col
                                    lg={5}
                                    md={6}
                                    sm={24}
                                    sx={24}
                                    className='border border-[#4D4D4D42] border-solid p-2 rounded-[5px]'
                                >
                                    <TooltipLabel
                                        label={t(
                                            'createPromotionModal.stepperCreatePromotion.transWhy',
                                        )}
                                        tooltipMessage={t(
                                            'createPromotionModal.stepperCreatePromotion.transWhyToolitipMessage',
                                        )}
                                    />
                                    <p className='text-sm'>
                                        {t(
                                            'createPromotionModal.stepperCreatePromotion.transWhyDescription',
                                        ) +
                                            localStorage.getItem(
                                                'currencySymbol',
                                            )}
                                    </p>
                                    <div className='flex items-center gap-2'>
                                        <Input
                                            label={
                                                t(
                                                    'createPromotionModal.stepperCreatePromotion.transMin',
                                                ) +
                                                localStorage.getItem(
                                                    'currencySymbol',
                                                )
                                            }
                                            onChange={handleChange}
                                            name='minObtainPromo'
                                            value={values.minObtainPromo}
                                            id='minObtainPromo'
                                            type='number'
                                            min={0}
                                            step='1'
                                            error={renderError(
                                                t,
                                                errors,
                                                'minObtainPromo',
                                            )}
                                        />
                                        <Input
                                            onChange={handleChange}
                                            min={0}
                                            label={
                                                t(
                                                    'createPromotionModal.stepperCreatePromotion.transMax',
                                                ) +
                                                localStorage.getItem(
                                                    'currencySymbol',
                                                )
                                            }
                                            name='maxObtainPromo'
                                            value={values.maxObtainPromo}
                                            id='maxObtainPromo'
                                            type='number'
                                            step='1'
                                            error={renderError(
                                                t,
                                                errors,
                                                'maxObtainPromo',
                                            )}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div className='h-60'></div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SecondStepperScreen;
